import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/data/CheatSheet.css';

const HRDroughtBatterGoodHistoryCheatSheet = () => {
  const { date } = useParams();
  const navigate = useNavigate();
  const [droughtData, setDroughtData] = useState([]);
  const [gameSummaries, setGameSummaries] = useState([]);
  const [error, setError] = useState('');
  const [expandedGames, setExpandedGames] = useState({});
  const [loading, setLoading] = useState(true); // Loading state

  const handleError = useCallback((error) => {
    if (error.response) {
      if (error.response.status === 403) {
        setError('You are not subscribed to this sport.');
      } else if (error.response.status === 404) {
        setError('Sport not found.');
      } else if (error.response.data && error.response.data.code === 'token_not_valid') {
        navigate('/login');
      } else {
        setError(`An unexpected error occurred: ${error.response.statusText}`);
      }
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
    setLoading(false); // Set loading to false in case of an error
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/pdf-data/${date}/0/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const droughtHistory = response.data.droughts_good_pitcher_history;
        const gameSummaries = response.data.game_summary;

        setDroughtData(droughtHistory);
        setGameSummaries(gameSummaries);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
  }, [date, handleError]);

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({
      ...prev,
      [gameId]: !prev[gameId],
    }));
  };

  // Filter and sort games that have associated drought data
  const filteredAndSortedGames = gameSummaries
    .filter((game) => droughtData.some((player) => player.game_id === game.game_id))
    .sort((a, b) => new Date(`1970-01-01T${a.game_time}Z`) - new Date(`1970-01-01T${b.game_time}Z`))
    .map((game) => ({
      ...game,
      players: droughtData.filter(player => player.game_id === game.game_id),
    }));

  if (loading) {
    return <div>Loading...</div>; // Show loading message while data is being fetched
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!filteredAndSortedGames.length) {
    return <div>No data available for this date.</div>;
  }

  return (
    <div className="cheat-sheet">
      <div className="header">
        <h1>HR Drought Batter with Good History Cheat Sheet for {date}</h1>
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      </div>
      <div className="team-list">
        {filteredAndSortedGames.map((game) => (
          <div key={game.game_id} className="team-section">
            <div className="team-header" onClick={() => toggleGameExpansion(game.game_id)}>
              {game.game_display_name}
              <img
                src={expandedGames[game.game_id] ? '/assets/icons/misc/collapse_icon.png' : '/assets/icons/misc/expand_icon.png'}
                alt={expandedGames[game.game_id] ? 'Collapse' : 'Expand'}
                className="expand-collapse-icon"
              />
            </div>
            {expandedGames[game.game_id] && (
              <div className="player-list">
                {game.players.map((player) => (
                  <div key={player.player_name} className="player-section">
                    <div className="player-name">{player.player_name}</div>
                    <br></br>
                    <div className="player-metrics">
                      <p>Current HR Drought: {player.current_drought} games</p>
                      <p>Longest HR Drought: {player.longest_drought} games</p>
                      <p>Average HR Drought: {player.average_drought} games</p>
                      <br></br>
                      <p>Home Runs Against Opposing Pitcher: {player.hrs_against_opposing_pitcher}</p>
                      <p>Hits Against Opposing Pitcher: {player.hits_against_opposing_pitcher}</p>
                      <p>AB Against Opposing Pitcher: {player.ab_against_opposing_pitcher}</p>
                      <br></br>
                      <p>Opposing Pitcher: {player.opposing_pitcher}</p>
                      <p>Opposing Pitcher Predicted Earned Runs: {player.opposing_pitcher_predicted_earned_runs}</p>
                      <p>Opposing Pitcher Predicted Hits Allowed: {player.opposing_pitcher_predicted_hits_allowed}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HRDroughtBatterGoodHistoryCheatSheet;
