import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import '../../../styles/data/HRStats.css';

const HRStats = () => {
  const [players, setPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([null, null]);
  const [hrStats, setHrStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const handleError = useCallback((error) => {
    if (error.response) {
      setError(`An unexpected error occurred: ${error.response.statusText}`);
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
  }, []);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/retrieve-mlb-players/`
        );
        const sortedPlayers = response.data.sort((a, b) =>
          a.player_name.localeCompare(b.player_name)
        );
        setPlayers(sortedPlayers);
      } catch (error) {
        handleError(error);
      }
    };

    fetchPlayers();
  }, [handleError]);

  const handlePlayerSelection = (selectedOption, index) => {
    const newSelection = [...selectedPlayers];
    newSelection[index] = selectedOption ? selectedOption.value : null;
    setSelectedPlayers(newSelection);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filteredSelectedPlayers = selectedPlayers.filter((player) => player !== null);

    if (filteredSelectedPlayers.length < 2) {
      setError('Please select at least 2 players.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/retrieve-mlb-hr-stats/`,
        {
          player_ids: filteredSelectedPlayers,
        }
      );
      setHrStats(response.data);
    } catch (error) {
      setError('Failed to fetch HR stats.');
    } finally {
      setLoading(false);
    }
  };

  const renderStats = () => {
    if (!hrStats) return null;
  
    return (
      <div className="metric-section">
        <h3>HR Stats (For days all batters played)</h3>
        <p>Total Days All Selected Players Hit HRs: {hrStats.all_hr_days}</p>
        <p>Days None of the Selected Players Hit HRs: {hrStats.none_hr_days}</p>
        <p>Days At Least One Player Hit a HR: {hrStats.single_hr_days}</p>
  
        {hrStats.all_hr_dates && hrStats.all_hr_dates.length > 0 && (
          <div>
            <button onClick={() => setIsExpanded(!isExpanded)} className="expand-button">
              {isExpanded ? 'Hide Details' : 'Show Details'}
            </button>
            {isExpanded && (
              <div className="expanded-section">
                <h4>All HR Dates:</h4>
                <ul>
                  {hrStats.all_hr_dates.map((date, index) => (
                    <li key={index}>{date}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const playerOptions = players.map((player) => ({
    value: player.player_id,
    label: player.player_name,
  }));

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  return (
    <div className="hr-stats-container">
      <button className="back-button" onClick={handleBackClick}>
        Back
      </button>

      <h1>MLB Home Run Stats</h1>

      <form onSubmit={handleSubmit}>
        <div className="player-selection">
          {selectedPlayers.map((_, index) => (
            <Select
              key={index}
              options={playerOptions}
              isSearchable
              value={playerOptions.find((option) => option.value === selectedPlayers[index]) || null}
              onChange={(selectedOption) => handlePlayerSelection(selectedOption, index)}
              placeholder="Select a player"
              className="player-dropdown"
            />
          ))}
          <button
            type="button"
            className="add-player-button"
            onClick={() => setSelectedPlayers([...selectedPlayers, null])}
          >
            Add Another Player
          </button>
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Loading...' : 'Get HR Stats'}
        </button>
      </form>
      <div className="hr-stats-results">{renderStats()}</div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default HRStats;
