import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const AuthCheck = ({ children, redirectToDashboard = false }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current URL path

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('accessToken');
      
      if (!token) {
        navigate('/');
      } else {
        try {
          await axios.get(`${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}check-auth/`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          
          // If authenticated and redirectToDashboard is true, navigate to the dashboard
          // But only navigate to the dashboard if the current path is not already "/"
          if (redirectToDashboard && location.pathname === '/') {
            navigate('/dashboard');
          }
        } catch (error) {
          // If the token is invalid or expired, redirect to login page
          navigate('/home');
        }
      }
    };

    checkAuthentication();
  }, [navigate, redirectToDashboard, location.pathname]);

  return <>{children}</>;
};

export default AuthCheck;
