import React from 'react';
import '../../styles/core/Auth.css';

const PrivacyPolicy = () => {
  return (
    <div className="auth-container">
      <div className="auth">
        <h2>Privacy Policy</h2>
        <p>Your privacy is important to us. This policy explains how we handle and use your personal information at SportBetData.ai.</p>
        
        <h3>Information Collection</h3>
        <p>We collect information to provide better services to our users. This includes information you provide to us directly, such as when you create an account, subscribe to our services, or contact us.</p>
        
        <h3>Use of Information</h3>
        <p>The information we collect is used to improve our services, provide personalized experiences, and communicate with you effectively. We use your information to process transactions, send notifications, provide customer support, and analyze how our services are used to enhance user experience and service performance.</p>
        
        <h3>Information Sharing</h3>
        <p>We do not share personal information with companies, organizations, or individuals outside of SportBetData.ai unless one of the following circumstances applies: (1) With your consent; (2) For external processing with trusted partners who comply with our privacy policy; (3) For legal reasons, such as to comply with any applicable law, regulation, legal process, or enforceable governmental request.</p>

        <h3>Data Security</h3>
        <p>We are committed to protecting your data. We implement various security measures to safeguard your personal information from unauthorized access, use, or disclosure. These measures include encryption, access controls, and secure data storage solutions.</p>
        
        <h3>Your Rights</h3>
        <p>You have the right to access, update, and delete your personal information. You can manage your information through your account settings or by contacting us directly.</p>

        <p>Please contact us if you have any questions about our privacy practices. We are committed to protecting your privacy and ensuring the security of your personal information.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
