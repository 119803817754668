import axios from 'axios';

const BASE_URL = process.env.REACT_APP_DJANGO_BASE_URL;
const API_TYPE = process.env.REACT_APP_USER_API_TYPE;

/*
########################################################################
# Helper Function for Error Handling
########################################################################
*/

const extractErrorMessage = (error) => {
  if (error.response && error.response.data) {
    const status = error.response.status;
    const errorMessages = error.response.data;

    if (status >= 400 && status < 500) {
      let errorMessage = '';
      if (Array.isArray(errorMessages)) {
        errorMessage = errorMessages.join(', ');
      } else if (typeof errorMessages === 'object') {
        errorMessage = Object.values(errorMessages).flat().join(', ');
      } else {
        errorMessage = errorMessages;
      }

      // Additional details for specific status codes
      if (status === 400) {
        errorMessage = `Bad Request: ${errorMessage}`;
      } else if (status === 401) {
        errorMessage = `Unauthorized: ${errorMessage}`;
      } else if (status === 403) {
        errorMessage = `Forbidden: ${errorMessage}`;
      } else if (status === 404) {
        errorMessage = `Not Found: ${errorMessage}`;
      }

      return errorMessage;
    } else {
      return 'An unexpected error occurred. Please try again later.';
    }
  } else {
    return 'Network error';
  }
};

/*
########################################################################
# Additional Helpers
########################################################################
*/

export const isAuthenticated = () => {
  const token = localStorage.getItem('accessToken');
  return !!token;
};

export const refreshToken = async () => {
  const url = `${BASE_URL}${API_TYPE}token/refresh/`;
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    const response = await axios.post(url, { refresh: refreshToken });
    const { access } = response.data;
    localStorage.setItem('accessToken', access);
    return access;
  } catch (error) {
    throw new Error(extractErrorMessage(error));
  }
};

/*
########################################################################
# Auth Endpoints
########################################################################
*/

export const loginUser = async (email, password) => {
  const url = `${BASE_URL}${API_TYPE}login/`;
  try {
    const response = await axios.post(url, { email, password }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { access, refresh } = response.data;
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
    return response.data;
  } catch (error) {
    throw new Error(extractErrorMessage(error));
  }
};

export const registerUser = async (formData) => {
  const url = `${BASE_URL}${API_TYPE}register/`;
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { access, refresh } = response.data;
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
    return response.data;
  } catch (error) {
    throw new Error(extractErrorMessage(error));
  }
};

export const confirmEmail = async (token) => {
  const url = `${BASE_URL}${API_TYPE}confirm-email/`;
  const accessToken = localStorage.getItem('accessToken');
  try {
    const response = await axios.post(url, { token }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(extractErrorMessage(error));
  }
};

export const resendEmailCode = async () => {
  const url = `${BASE_URL}${API_TYPE}regenerate-tokens/`;
  const accessToken = localStorage.getItem('accessToken');
  try {
    const response = await axios.post(
      url,
      { regenerate_type: 'email' },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(extractErrorMessage(error));
  }
};

export const forgotPassword = async (email) => {
  const url = `${BASE_URL}${API_TYPE}regenerate-tokens/`;
  try {
    const response = await axios.post(
      url,
      { regenerate_type: 'forgot_password', email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(extractErrorMessage(error));
  }
};

