import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getSubscribedSports } from '../../api/subscriptionsApi';
import Subscribe from '../payment/Subscribe';
import '../../styles/core/Dashboard.css';

const Dashboard = () => {
  const [subscribedSports, setSubscribedSports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false); // For subscription modal
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false); // New state
  const [activeSlide, setActiveSlide] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        navigate('/login');
      } else {
        try {
          const subscribedSportsData = await getSubscribedSports(token);
          setSubscribedSports(subscribedSportsData);

          if (subscribedSportsData && subscribedSportsData.length > 0) {
            setHasActiveSubscription(true);
          } else {
            setHasActiveSubscription(false);
          }

          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);

          if (error.response && error.response.status === 404) {
            setHasActiveSubscription(false);
            setLoading(false);
          } else if (error.response && error.response.data && error.response.data.code === 'token_not_valid') {
            navigate('/login');
          } else {
            setHasActiveSubscription(false);
            setLoading(false);
          }
        }
      }
    };

    checkAuthentication();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  const handleSportClick = (sport) => {
    const url = `/sport/${sport.sport_id}`;
    window.open(url, '_blank');
  };

  const closeSubscribeModal = () => {
    setShowSubscribeModal(false); // Close the subscribe modal
  };

  const handleNextSlide = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrevSlide = () => {
    setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  // Generate individual slides for each sport in the subscribed list
  const subscribedSportsSlides = subscribedSports.map((sport, index) => ({
    header: 'Subscribed Sports',
    content: (
      <>
        <div className="section-ribbon" onClick={() => handleSportClick(sport)}>Subscribed Sport</div>
        <div className="sport-card" onClick={() => handleSportClick(sport)}> {/* Make sports clickable */}
          <img src={`data:image/jpeg;base64,${sport.sport_image}`} alt={sport.sport} />
          <div className="sport-name">{sport.sport}</div>
        </div>
      </>
    ),
    condition: true,
  }));

  // HR insights are static slides and should always show
  const staticSlides = [
    {
      header: 'Settings & Additional Information',
      content: (
        <>
          <div className="section-ribbon">Settings & Additional Information</div>
          <div className="info-cards">
            <div className="info-card">
              <Link to="/user-settings" target="_blank" rel="noopener noreferrer">
                <img src="/assets/images/user-settings.png" alt="User Settings" />
                <p>User Settings</p>
              </Link>
            </div>
            <div className="info-card">
              <Link to="/faq" target="_blank" rel="noopener noreferrer">
                <img src="/assets/images/faq.png" alt="FAQ" />
                <p>FAQ</p>
              </Link>
            </div>
            <div className="info-card">
              <a href="/file-issue" target="_blank" rel="noopener noreferrer">
                <img src="/assets/images/file-issue.webp" alt="File Issue" />
                <p>File Issue</p>
              </a>
            </div>
          </div>
        </>
      ),
      condition: true, // Always show settings regardless of subscription status
    },
    {
      header: 'Logout',
      content: (
        <>
          <div className="section-ribbon" onClick={handleLogout}>Logout</div>
          <div className="logout-card" onClick={handleLogout}>
            <img src="/assets/images/logout.ico" alt="Logout" />
            <p>Click to logout</p>
          </div>
        </>
      ),
      condition: true, // Logout should always be available
    },
  ];

  // Inside the subscriptionSlide object in your Dashboard component
  const subscriptionSlide = {
    header: 'Subscribe Now',
    content: (
      <>
        <div className="section-ribbon" onClick={() => setShowSubscribeModal(true)}>Subscribe to Access All Sports</div>
        <div className="subscribe-card" onClick={() => setShowSubscribeModal(true)}> {/* Trigger subscription modal */}
          <img src="/assets/images/subscribe.ico" alt="Subscribe" />
          <p>Get full access to sports insights and detailed performance analytics.</p>
          <p>Before subscribing, you can <Link to="/sample-data-page" className="sample-data-button">view sample data</Link> to explore what SportBetData.ai offers.</p>
        </div>
      </>
    ),
    condition: !hasActiveSubscription, // Show this if the user has no active subscription
  };

  // Unsubscribed users should still see Logout, HR Insights, and static slides
  const slides = hasActiveSubscription
    ? [...subscribedSportsSlides, ...staticSlides] // Show sports, betting slips, and static slides
    : [subscriptionSlide, ...staticSlides]; // Show subscription option + HR insights + static content for unsubscribed users

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % slides.length);
    }, 30000);

    return () => clearInterval(interval);
  }, [slides.length]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="carousel-section">
        <div className="section-header">
          <h1>{slides[activeSlide].header}</h1>
        </div>
        <div className="section-content">
          {slides[activeSlide].content}
        </div>
        <div className="carousel-controls">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === activeSlide ? 'active' : ''}`}
              onClick={() => setActiveSlide(index)}
            ></span>
          ))}
        </div>
      </div>
      <button className="carousel-button prev" onClick={handlePrevSlide}>&lt;</button>
      <button className="carousel-button next" onClick={handleNextSlide}>&gt;</button>
      {showSubscribeModal && (
        <div className="subscribe-modal">
          <Subscribe onClose={closeSubscribeModal} /> {/* Opens the subscription modal */}
        </div>
      )}
      <img src="/assets/images/sportbetdata_full_logo.png" alt="Sport Bet Data Logo" className="bottom-logo" />
    </div>
  );
};

export default Dashboard;
