import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { confirmEmail, resendEmailCode } from '../../api/authApi';
import '../../styles/core/Auth.css';

const ConfirmEmail = () => {
  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Get the email passed from the Register component
  const email = location.state?.email || ''; 

  const handleConfirmEmail = async (e) => {
    e.preventDefault();
    try {
      await confirmEmail(token);
      setSuccessMessage('Email confirmed successfully.');
      navigate('/dashboard');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleResendCode = async () => {
    try {
      await resendEmailCode();
      setSuccessMessage('A new code has been sent to your email.');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth">
        <h2>Confirm Email</h2>
        <p>
          A confirmation code has been sent to {email}. Please enter the code below.
          If you don't receive it within 30 seconds, click "Resend Code".
        </p>
        <p>
          View disclaimer on the bottom.
        </p>
        <form onSubmit={handleConfirmEmail}>
          <div className="form-group">
            <label>Confirmation Code</label>
            <input
              type="text"
              name="token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              required
            />
          </div>
          <button type="submit">Confirm</button>
        </form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
        <button onClick={handleResendCode}>Resend Code</button>
        <div className="auth-links">
          <Link to="/">Back to Home</Link>
        </div>
        <p className="disclaimer">Verifying your email is not necessary to proceed with application. You can click "Back to Home". However, if you forget your credentials with an active subscription, you will need to reach out to support@sportbetdata.ai</p>
      </div>
    </div>
  );
};

export default ConfirmEmail;
