// SampleDataPage.js
import React from 'react';
import '../../styles/core/SampleDataPage.css';

const SampleDataPage = () => {
  // Set the total number of images you expect in the folder
  const totalImages = 9; // Adjust this number as needed

  // Generate paths for images based on the pattern "pic_1.jpg", "pic_2.jpg", etc.
  const reportImages = Array.from({ length: totalImages }, (_, index) => `/assets/reports/pic_${index + 1}.jpg`);

  return (
    <div className="sample-data-page">
      <h1>All of the reports below were generated from the data on SportBetData.ai</h1>
      <p>Check our <a href="https://twitter.com/sportbetdata" target="_blank" rel="noopener noreferrer">@sportbetdata</a> on X to see the traction on each report.</p>
      <div className="image-grid">
        {reportImages.map((image, index) => (
          <div key={index} className="grid-item">
            <img src={image} alt={`Report ${index + 1}`} onError={(e) => e.target.style.display = 'none'} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SampleDataPage;
