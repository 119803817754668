import axios from 'axios';

const BASE_URL = process.env.REACT_APP_DJANGO_BASE_URL;
const USER_API_TYPE = process.env.REACT_APP_USER_API_TYPE;
const SUBSCRIPTION_API_TYPE = process.env.REACT_APP_SUBSCRIPTION_API_TYPE;

export const getUserInfo = async (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(`${BASE_URL}${USER_API_TYPE}user-info/`, { headers });
  return response.data;
};

export const getSubscribedSports = async (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(`${BASE_URL}${SUBSCRIPTION_API_TYPE}view-active-user-subscriptions/`, { headers });
  return response.data;
};