import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../styles/data/NFLClusterReport.css';

const NFLClusterReport = () => {
  const [clusterData, setClusterData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [opposingDefenseList, setOpposingDefenseList] = useState([]);
  const [selectedOpposingDefense, setSelectedOpposingDefense] = useState('');
  const [playerNameFilter, setPlayerNameFilter] = useState('');
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [showFilteredPlayers, setShowFilteredPlayers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // eslint-disable-next-line
  const [visibleColumns, setVisibleColumns] = useState({
    opposingDefense: true,
    fppgCluster: true,
    positionName: true,
    avgFppg: true,
    avgRushingYardsAgainst: true,
    avgRushingTdsAgainst: true,
    avgReceptionsAllowed: true,
    avgReceivingYardsAgainst: true,
    avgReceivingTdsAgainst: true,
    playerList: true,
  });
  const [minimizedRows, setMinimizedRows] = useState(new Set());

  useEffect(() => {
    const fetchClusterData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/cluster-report/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (Array.isArray(response.data.nfl_cluster_data)) {
          setClusterData(response.data.nfl_cluster_data);
          setFilteredData(response.data.nfl_cluster_data);

          // Extract unique opposing defenses for the dropdown
          const uniqueOpposingDefenses = [...new Set(response.data.nfl_cluster_data.map(item => item.opposing_defense))];
          setOpposingDefenseList(uniqueOpposingDefenses);
        } else {
          setClusterData([]);
          setFilteredData([]);
        }
        setLoading(false);
      } catch (error) {
        setError('Error fetching cluster report data.');
        setLoading(false);
      }
    };

    const fetchTeamData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const teamResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/player-team-map/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (Array.isArray(teamResponse.data.nfl_player_teams)) {
          setTeamList(teamResponse.data.nfl_player_teams);
        } else {
          setTeamList([]);
        }
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };

    fetchClusterData();
    fetchTeamData();
  }, []);

  // Handle filtering the data by opposing defense, player name, and selected team
  const handleFilterChange = () => {
    let filtered = clusterData;

    // Filter by selected opposing defense
    if (selectedOpposingDefense) {
      filtered = filtered.filter(item =>
        item.opposing_defense.toLowerCase() === selectedOpposingDefense.toLowerCase()
      );
    }

    // Filter by player name (case-insensitive search)
    const trimmedPlayerFilter = playerNameFilter.trim().toLowerCase();
    if (trimmedPlayerFilter) {
      filtered = filtered.filter(item =>
        item.player_list.toLowerCase().includes(trimmedPlayerFilter)
      );
    }

    // Filter by selected offensive team
    if (selectedTeam) {
      const playersInSelectedTeam = teamList
        .filter(teamPlayer => teamPlayer.team_name === selectedTeam)
        .map(teamPlayer => teamPlayer.player_name.toLowerCase());

      filtered = filtered.filter(item =>
        playersInSelectedTeam.some(player => item.player_list.toLowerCase().includes(player))
      );
    }

    setFilteredData(filtered);
  };

  // Helper function to get filtered players
  const getFilteredPlayerList = (playerList) => {
    if (!selectedTeam) {
      return playerList; // Return all players if no team is selected
    }

    const playersInSelectedTeam = teamList
      .filter(teamPlayer => teamPlayer.team_name === selectedTeam)
      .map(teamPlayer => teamPlayer.player_name.toLowerCase());

    return playerList
      .split(", ")
      .filter(player => playersInSelectedTeam.includes(player.toLowerCase()))
      .join(", ");
  };

  // Toggle row visibility (minimize/expand)
  const toggleRowVisibility = (index) => {
    const newMinimizedRows = new Set(minimizedRows);
    if (minimizedRows.has(index)) {
      newMinimizedRows.delete(index); // Expand row
    } else {
      newMinimizedRows.add(index); // Minimize row
    }
    setMinimizedRows(newMinimizedRows);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="cluster-report-container">
      <h1>NFL Cluster Report</h1>

      <div className="filter-container">
        <label htmlFor="opposingDefenseFilter">Filter by Opposing Defense: </label>
        <select id="opposingDefenseFilter" value={selectedOpposingDefense} onChange={(e) => setSelectedOpposingDefense(e.target.value)}>
          <option value="">Select a Defense</option>
          {opposingDefenseList.map((defense, index) => (
            <option key={index} value={defense}>{defense}</option>
          ))}
        </select>

        <label htmlFor="playerNameFilter">Filter by Player Name: </label>
        <input
          type="text"
          id="playerNameFilter"
          value={playerNameFilter}
          onChange={(e) => setPlayerNameFilter(e.target.value)}
          placeholder="Enter player name"
        />

        <label htmlFor="teamFilter">Filter by Offensive Team: </label>
        <select id="teamFilter" value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
          <option value="">Select a Team</option>
          {[...new Set(teamList.map(player => player.team_name))].map((team, index) => (
            <option key={index} value={team}>{team}</option>
          ))}
        </select>

        <button onClick={handleFilterChange} className="filter-button">Search</button>
      </div>

      <div className="player-view-toggle">
        <label>
          <input
            type="checkbox"
            checked={showFilteredPlayers}
            onChange={() => setShowFilteredPlayers(!showFilteredPlayers)}
          />
          Show only players from the selected offensive team
        </label>
      </div>

      <table className="cluster-report-table">
        <thead>
          <tr>
            {visibleColumns.opposingDefense && <th>Opposing Defense</th>}
            {visibleColumns.fppgCluster && <th>FPPG Cluster</th>}
            {visibleColumns.positionName && <th>Position</th>}
            {visibleColumns.avgFppg && <th>Avg FPPG</th>}
            {visibleColumns.avgRushingYardsAgainst && <th>Avg Rushing Yards</th>}
            {visibleColumns.avgRushingTdsAgainst && <th>Avg Rushing TDs</th>}
            {visibleColumns.avgReceptionsAllowed && <th>Avg Receptions</th>}
            {visibleColumns.avgReceivingYardsAgainst && <th>Avg Receiving Yards</th>}
            {visibleColumns.avgReceivingTdsAgainst && <th>Avg Receiving TDs</th>}
            {visibleColumns.playerList && <th>Cluster Players</th>}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map((metric, index) => (
              <tr key={index}>
                <td>{metric.opposing_defense}</td>
                <td>{metric.fppg_cluster}</td>
                <td>{metric.position_name}</td>
                <td>{metric.avg_fppg}</td>
                <td>{metric.avg_rushing_yards_against}</td>
                <td>{metric.avg_rushing_tds_against}</td>
                <td>{metric.avg_receptions_allowed}</td>
                <td>{metric.avg_receiving_yards_against}</td>
                <td>{metric.avg_receiving_tds_against}</td>
                <td>{showFilteredPlayers ? getFilteredPlayerList(metric.player_list) : metric.player_list}</td>
                <td>
                  <button onClick={() => toggleRowVisibility(index)}>{minimizedRows.has(index) ? '+' : '-'}</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12">No data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NFLClusterReport;
