import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';  // Import the spinner
import '../../../styles/data/CheatSheet.css';

const MLBPitcherPropTrendHistoryCheatSheet = () => {
  const navigate = useNavigate();
  const [trendData, setTrendData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);  // Loading state
  const [expandedPlayers, setExpandedPlayers] = useState({});

  // Handle any API errors
  const handleError = useCallback((error) => {
    if (error.response) {
      if (error.response.status === 403) {
        setError('You are not subscribed to this sport.');
      } else if (error.response.status === 404) {
        setError('Sport not found.');
      } else if (error.response.data && error.response.data.code === 'token_not_valid') {
        // Redirect to login if token is invalid
        navigate('/login');
      } else {
        setError(`An unexpected error occurred: ${error.response.statusText}`);
      }
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
    setLoading(false);  // Stop loading if an error occurs
  }, [navigate]);

  // Fetch trend history data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/retrieve-mlb-pitcher-prop-trends/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Sort the data by player name and then by metric
        const sortedTrendData = response.data.sort((a, b) => {
          if (a.player_name < b.player_name) return -1;
          if (a.player_name > b.player_name) return 1;
          return a.metric.localeCompare(b.metric);
        });

        setTrendData(sortedTrendData);
        setLoading(false);  // Stop loading when data is fetched
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, [handleError]);

  // Toggle expansion of player sections
  const togglePlayerExpansion = (playerId) => {
    setExpandedPlayers((prev) => ({
      ...prev,
      [playerId]: !prev[playerId],
    }));
  };

  // Error rendering
  if (error) {
    return <div>{error}</div>;
  }

  // Loading state with spinner and message
  if (loading) {
    return (
      <div className="loading-container">
        <Oval
          height={50}
          width={50}
          color="blue"
          ariaLabel="loading"
          secondaryColor="#f3f3f3"
          strokeWidth={3}
        />
        <p>This report takes approximately 10 seconds to load. Please wait...</p>
      </div>
    );
  }

  // Extract player names from trend data
  const players = Array.from(new Set(trendData.map(trend => trend.player_name)));

  // Render the cheat sheet
  return (
    <div className="cheat-sheet">
      <div className="header">
        <h1>Pitcher Prop Trend History Cheat Sheet</h1>
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      </div>
      <div className="player-list">
        {players.map((player) => (
          <div key={player} className="player-section">
            <div className="player-header" onClick={() => togglePlayerExpansion(player)}>
              {player} (View Prop Trends. Click to expand)
              <br></br>
              <img
                src={expandedPlayers[player] ? '/assets/icons/misc/collapse_icon.png' : '/assets/icons/misc/expand_icon.png'}
                alt={expandedPlayers[player] ? 'Collapse' : 'Expand'}
                className="expand-collapse-icon"
              />
            </div>
            {expandedPlayers[player] && (
              <div className="metric-list">
                {trendData
                  .filter(trend => trend.player_name === player)
                  .map((trend) => (
                    <div key={trend.metric} className="metric-row">
                      <div className="metric">
                        {trend.metric}: O/U {trend.over_under}
                      </div>
                      <div className="metric">Current Streak: {trend.current_streak} ({trend.streak_direction})</div>
                      <br></br>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MLBPitcherPropTrendHistoryCheatSheet;
