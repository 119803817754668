import React from 'react';
import '../../styles/core/Faq.css';

const FAQ = ({ faqData }) => {
  return (
    <div className="faq-container">
      <h3>Frequently Asked Questions</h3>
      <div className="faq-list">
        {Object.entries(faqData).map(([question, answer], index) => (
          <div key={index} className="faq-item">
            <h4 className="faq-question">{question}</h4>
            <p className="faq-answer">{answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
