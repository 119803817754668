// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  text-align: center;
  padding: 2em;
}

.home .logo {
  width: 70%;
  max-width: 600px;
  margin: 0 auto 1em auto;
  display: block;
  border-radius: 50%; /* Ensures the image is a circle */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Circular drop shadow */
}

.home .buttons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 2em;
}

.home .button, .home .blue-button {
  flex: 1 1;
  margin: 0.5em;
  padding: 1em 1em;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.home .button:hover, .home .blue-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.home .button {
  background-color: #484242;
}

.home .blue-button {
  background-color: #95CBF2;
}

@media (max-width: 768px) {
  .home .logo {
    width: 90%;
  }

  .home .button, .home .blue-button {
    flex: 1 1 100%;
    margin: 0.5em 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/core/Home.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,uBAAuB;EACvB,cAAc;EACd,kBAAkB,EAAE,kCAAkC;EACtD,uCAAuC,EAAE,yBAAyB;AACpE;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;EACE,2BAA2B;EAC3B,0CAA0C;AAC5C;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,cAAY;IACZ,eAAe;EACjB;AACF","sourcesContent":[".home {\n  text-align: center;\n  padding: 2em;\n}\n\n.home .logo {\n  width: 70%;\n  max-width: 600px;\n  margin: 0 auto 1em auto;\n  display: block;\n  border-radius: 50%; /* Ensures the image is a circle */\n  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Circular drop shadow */\n}\n\n.home .buttons {\n  display: flex;\n  justify-content: space-around;\n  flex-wrap: wrap;\n  margin-top: 2em;\n}\n\n.home .button, .home .blue-button {\n  flex: 1;\n  margin: 0.5em;\n  padding: 1em 1em;\n  color: white;\n  text-decoration: none;\n  border-radius: 5px;\n  text-align: center;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  transition: transform 0.2s, box-shadow 0.2s;\n}\n\n.home .button:hover, .home .blue-button:hover {\n  transform: translateY(-3px);\n  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);\n}\n\n.home .button {\n  background-color: #484242;\n}\n\n.home .blue-button {\n  background-color: #95CBF2;\n}\n\n@media (max-width: 768px) {\n  .home .logo {\n    width: 90%;\n  }\n\n  .home .button, .home .blue-button {\n    flex: 1 100%;\n    margin: 0.5em 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
