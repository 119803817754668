import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import '../../styles/data/GameDetails.css';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const NBAGameDetails = () => {
  const { date, game_id } = useParams();
  const [gameData, setGameData] = useState(null);
  const [teamMetricsData, setTeamMetricsData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [positionMetricsData, setPositionMetricsData] = useState([]);
  const [nbaClusterData, setNbaClusterData] = useState([]);
  const [isCheatSheetView, setIsCheatSheetView] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleError = useCallback((error) => {
    if (error.response) {
      if (error.response.status === 403) {
        setError('You do not have an active subscription.');
      } else if (error.response.status === 404) {
        setError('Sport not found.');
      } else if (error.response.data && error.response.data.code === 'token_not_valid') {
        navigate('/login');
      } else {
        setError(`An unexpected error occurred: ${error.response.statusText}`);
      }
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
  }, [navigate]);

  useEffect(() => {
    const checkPermissionsAndFetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const permissionResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}check-sport-permissions/1/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (permissionResponse.status === 200) {
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/pdf-data/${date}/${game_id}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setGameData(response.data);

          const metricsResponse = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/team-metrics/${date}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setTeamMetricsData(metricsResponse.data);
        }
      } catch (error) {
        handleError(error);
      }
    };

    checkPermissionsAndFetchData();
  }, [date, game_id, navigate, handleError]);

  useEffect(() => {
    const fetchTrendData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const trendsResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/prop-trends/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const filteredTrendData = trendsResponse.data.filter(trend => String(trend.game_id) === String(game_id));
        setTrendData(filteredTrendData);
      } catch (error) {
        handleError(error);
      }
    };

    if (isCheatSheetView) {
      fetchTrendData();
    }
  }, [isCheatSheetView, game_id, handleError]);

  useEffect(() => {
    const fetchPositionMetricsData = async () => {
      if (!isCheatSheetView || !gameData) return;

      const token = localStorage.getItem('accessToken');
      const { away_team: awayTeam, home_team: homeTeam } = gameData.games.find(game => game.game_id.toString() === game_id);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/position-metrics-defense/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const filteredPositionMetrics = response.data.position_metrics_by_defense_vs_positions.filter(
          (metric) => metric.opposing_defense === awayTeam || metric.opposing_defense === homeTeam
        );

        setPositionMetricsData(filteredPositionMetrics);
      } catch (error) {
        handleError(error);
      }
    };

    if (isCheatSheetView) {
      fetchPositionMetricsData();
    }
  }, [isCheatSheetView, gameData, game_id, handleError]);

  useEffect(() => {
    const fetchClusterData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/cluster-report/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data && response.data.nba_cluster_data) {
          setNbaClusterData(response.data.nba_cluster_data);
        }
      } catch (error) {
        handleError(error);
      }
    };

    fetchClusterData();
  }, [handleError]);

  const toggleExpansion = (teamName, period) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [`${teamName}-${period}`]: !prevExpandedSections[`${teamName}-${period}`],
    }));
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!gameData) {
    return <div>Loading...</div>;
  }

  const { games, game_summary, player_predictions, recent_performances, odd_comparison, player_odds } = gameData;

  const selectedGame = games.find((game) => game.game_id.toString() === game_id);
  if (!selectedGame) {
    return <div>No game data available for this game ID.</div>;
  }

  const awayTeam = { name: selectedGame.away_team };
  const homeTeam = { name: selectedGame.home_team };

  const awayTeamImage = selectedGame.away_team_image_binary;
  const homeTeamImage = selectedGame.home_team_image_binary;

  const awayTeamSummary = game_summary.find(summary => summary.player_team === awayTeam.name);
  const homeTeamSummary = game_summary.find(summary => summary.player_team === homeTeam.name);

  const awayTeamPoints = awayTeamSummary ? parseFloat(awayTeamSummary.team_points) : 0;
  const homeTeamPoints = homeTeamSummary ? parseFloat(homeTeamSummary.team_points) : 0;
  let totalPoints = awayTeamPoints + homeTeamPoints;
  totalPoints = parseFloat(totalPoints.toFixed(2));

  const mapPlayerOdds = (playerName, playerOdds) => {
    return playerOdds
      .filter((odd) => odd.player_name === playerName)
      .map((odd) => ({
        metric: odd.metric,
        overUnder: odd.over_under,
        bettingOver: parseFloat(odd.betting_100_on_over_would_return),
        bettingUnder: parseFloat(odd.betting_100_on_under_would_return)
      }));
  };

  const createChartData = (predictions, recentPerformances, playerOdds, metric) => {
    const metricKeyMap = {
      'Points': 'points',
      'Rebounds': 'rebounds',
      'Assists': 'assists',
      '3PM': 'threePointMade',
      'PRA': 'PRA'
    };

    const metricKey = metricKeyMap[metric];
    const sortedPerformances = recentPerformances.sort((a, b) => new Date(a.date) - new Date(b.date));

    const recentPerformanceValues = sortedPerformances.map(p => {
      if (metric === 'PRA') {
        return parseFloat(p.points) + parseFloat(p.rebounds) + parseFloat(p.assists);
      }
      return parseFloat(p[metricKey]);
    }).filter(v => !isNaN(v));

    if (recentPerformanceValues.length === 0) {
      return {
        labels: [],
        datasets: []
      };
    }

    const predictionValue = parseFloat(predictions.find((p) => p.metric === metric).value);

    const vegasLine = playerOdds.find((odd) => odd.metric === metric)?.overUnder;
    const vegasLineValue = vegasLine ? parseFloat(vegasLine) : null;

    return {
      labels: sortedPerformances.map((p) => p.date),
      datasets: [
        {
          label: 'Recent Performances',
          data: recentPerformanceValues,
          pointBackgroundColor: 'yellow',
          pointBorderColor: 'yellow',
          pointRadius: 5,
          showLine: false,
        },
        {
          label: 'Prediction Line',
          data: Array(recentPerformanceValues.length).fill(predictionValue),
          borderColor: 'green',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          label: 'Vegas Line',
          data: vegasLineValue !== null ? Array(recentPerformanceValues.length).fill(vegasLineValue) : [],
          borderColor: 'red',
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
      },
    },
  };

  const awayPlayers = player_predictions
    .filter((player) => player.player_team === selectedGame.away_team && player.predicted_minutes > 1)
    .map((player) => ({
      name: player.player_name,
      predictions: [
        { metric: 'Points', value: player.predicted_points },
        { metric: 'Assists', value: player.predicted_assists },
        { metric: 'Rebounds', value: player.predicted_rebounds },
        { metric: '3PM', value: player.predicted_three_point_made },
        { metric: 'PRA', value: player.predicted_points_rebound_assist },
      ],
      recentPerformances: recent_performances
        .filter((performance) => performance.player === player.player_name && performance.player_team === selectedGame.away_team)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((performance) => ({
          date: performance.game_date,
          points: performance.points,
          rebounds: performance.rebounds,
          assists: performance.assists,
          threePointMade: performance.three_point_made,
        })),
      playerOdds: mapPlayerOdds(player.player_name, player_odds)
    }));

  const homePlayers = player_predictions
    .filter((player) => player.player_team === selectedGame.home_team && player.predicted_minutes > 1)
    .map((player) => ({
      name: player.player_name,
      predictions: [
        { metric: 'Points', value: player.predicted_points },
        { metric: 'Assists', value: player.predicted_assists },
        { metric: 'Rebounds', value: player.predicted_rebounds },
        { metric: '3PM', value: player.predicted_three_point_made },
        { metric: 'PRA', value: player.predicted_points_rebound_assist },
      ],
      recentPerformances: recent_performances
        .filter((performance) => performance.player === player.player_name && performance.player_team === selectedGame.home_team)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((performance) => ({
          date: performance.game_date,
          points: performance.points,
          rebounds: performance.rebounds,
          assists: performance.assists,
          threePointMade: performance.three_point_made,
        })),
      playerOdds: mapPlayerOdds(player.player_name, player_odds)
    }));

    const renderNBAClusterReport = () => {
      // Combine awayPlayers and homePlayers names into a set for quick lookup
      const gamePlayers = new Set([...awayPlayers, ...homePlayers].map(player => player.name));
    
      // Filter cluster data to only include rows for the opposing defense and game players
      const filteredClusterData = nbaClusterData
        .filter((item) => item.opposing_defense === awayTeam.name || item.opposing_defense === homeTeam.name)
        .map((item) => {
          // Filter cluster players to only those in the game
          const filteredPlayers = item.player_list
            .split(', ')
            .filter(player => gamePlayers.has(player))
            .join(', ');
    
          return {
            ...item,
            player_list: filteredPlayers,  // Replace with filtered player list
          };
        })
        .filter(item => item.player_list.length > 0);  // Only include rows with relevant players
    
      return (
        <>
          <h2>NBA Cluster Report (Only showing relevant players in cluster. Based on games where players played 25 minutes or more.)</h2>
          <div className="cluster-report-container">
            <table className="cluster-report-table">
              <thead>
                <tr>
                  <th>Opposing Defense</th>
                  <th>PPG Cluster</th>
                  <th>Position</th>
                  <th>Avg Points</th>
                  <th>Avg Assists</th>
                  <th>Avg Rebounds</th>
                  <th>Avg 3PM</th>
                  <th>Avg Steals</th>
                  <th>Avg Blocks</th>
                  <th>Avg Turnovers</th>
                  <th>Cluster Players</th>
                </tr>
              </thead>
              <tbody>
                {filteredClusterData.map((metric, index) => (
                  <tr key={index}>
                    <td>{metric.opposing_defense}</td>
                    <td>{metric.ppg_cluster}</td>
                    <td>{metric.position_name}</td>
                    <td>{metric.avg_points}</td>
                    <td>{metric.avg_assists}</td>
                    <td>{metric.avg_rebounds}</td>
                    <td>{metric.avg_3pm}</td>
                    <td>{metric.avg_steals}</td>
                    <td>{metric.avg_blocks}</td>
                    <td>{metric.avg_turnovers}</td>
                    <td>{metric.player_list}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    };

  const renderTeamMetricsDropdown = (teamName) => {
    const filteredMetrics = teamMetricsData.filter(metric => metric.team_name === teamName);

    return (
      <div className="metrics-dropdown">
        {filteredMetrics.map((metric, index) => (
          <div key={index}>
            <button
              onClick={() => toggleExpansion(metric.team_name, metric.period)}
              className="period-dropdown"
            >
              {metric.period === 'last_7_days' ? 'Last 7' : metric.period === 'last_15_days' ? 'Last 15' : metric.period === 'last_30_days' ? 'Last 30' : 'Season'}
            </button>
            {expandedSections[`${metric.team_name}-${metric.period}`] && (
              <div className="nested-dropdown">
                <div className="metric">Average Points: {metric.avg_points}</div>
                <div className="metric">Average Threes: {metric.avg_threes_per_game}</div>
                <div className="metric">Average Assists: {metric.avg_assists}</div>
                <div className="metric">Average Rebounds: {metric.avg_rebounds}</div>
                <div className="metric">Average Points Allowed: {metric.avg_points_allowed}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="game-details">
      <div className="header">
        <h1>NBA Game Details for {date}</h1>
        <div>
          <button id="back-button" onClick={() => navigate(-1)}>Back</button>
        </div>
      </div>

      <div className="toggle-container">
        <button onClick={() => setIsCheatSheetView(!isCheatSheetView)}>
          {isCheatSheetView ? 'Show Detailed View' : 'Show Cheat Sheet'}
        </button>
      </div>

      <div className="team-logos">
        <img src={awayTeamImage ? `data:image/png;base64,${awayTeamImage}` : '/path/to/placeholder.png'} alt={`${awayTeam.name} logo`} />
        <img src={homeTeamImage ? `data:image/png;base64,${homeTeamImage}` : '/path/to/placeholder.png'} alt={`${homeTeam.name} logo`} />
      </div>

      {isCheatSheetView ? (
        <div className="cheat-sheet-view">
          {positionMetricsData.length > 0 && (
            <>
              <h2>Position Metrics Report (25 Minutes or More)</h2>
              <div className="position-metrics-container">
                <table className="position-metrics-table">
                  <thead>
                    <tr>
                      <th>Opposing Defense</th>
                      <th>Position</th>
                      <th>Avg Points Allowed</th>
                      <th>Avg Assists Allowed</th>
                      <th>Avg Rebounds Allowed</th>
                      <th>Avg Steals Allowed</th>
                      <th>Avg Blocks Allowed</th>
                      <th>Avg Turnovers Forced</th>
                    </tr>
                  </thead>
                  <tbody>
                    {positionMetricsData.map((metric, index) => (
                      <tr key={index}>
                        <td>{metric.opposing_defense}</td>
                        <td>{metric.position}</td>
                        <td>{metric.avg_points}</td>
                        <td>{metric.avg_assists}</td>
                        <td>{metric.avg_rebounds}</td>
                        <td>{metric.avg_steals}</td>
                        <td>{metric.avg_blocks}</td>
                        <td>{metric.avg_turnovers}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {renderNBAClusterReport()}

          {trendData.length > 0 && (
            <>
              <h2>Trend History Report - Streaks 5 or Greater</h2>
              <div className="trend-history-container">
                {trendData.map((trend, index) => (
                  <div key={index} className="trend-row">
                    <div>{trend.player_name}</div>
                    <div>{trend.metric}: O/U {trend.over_under}</div>
                    <div>Current Streak: {trend.current_streak} ({trend.streak_direction})</div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <div id="game-details-content">
          <div className="game-display-ribbon">{selectedGame.game_display_name}</div>

          <div className="row centered-content">
            <div className="card">
              <h2>Game Summary</h2>
              <p>{awayTeam.name} Projected Total Points: {awayTeamPoints}</p>
              <p>{homeTeam.name} Projected Total Points: {homeTeamPoints}</p>
              <p>Point Differential: {Math.abs(awayTeamPoints - homeTeamPoints).toFixed(2)} in favor of {awayTeamPoints > homeTeamPoints ? awayTeam.name : homeTeam.name}</p>
              <p>Total Points: {totalPoints}</p>
            </div>
          </div>

          <div className="row centered-content">
            <div>
              <h4>{awayTeam.name} Metrics</h4>
              {renderTeamMetricsDropdown(awayTeam.name)}
            </div>
            <div>
              <h4>{homeTeam.name} Metrics</h4>
              {renderTeamMetricsDropdown(homeTeam.name)}
            </div>
          </div>

          {odd_comparison && odd_comparison.length > 0 && 
            odd_comparison[0].vegas_predicted_total !== null && 
            odd_comparison[0].vegas_predicted_winner_spread !== null && (
              <div className="additional-section">
                <h2>Odd Comparison</h2>
                <p>Vegas Predicted Winner: {odd_comparison[0].vegas_predicted_winner}</p>
                <p>Vegas Predicted Total: {odd_comparison[0].vegas_predicted_total}</p>
                <p>Vegas Predicted Winner Spread: {odd_comparison[0].vegas_predicted_winner_spread}</p>
              </div>
          )}

          <div className="team-ribbon">
            {awayTeam.name}
          </div>
          <div className="team-details">
            {awayPlayers.map(player => (
              <div key={player.name} className="player-details">
                <h3>{player.name}</h3>
                <div className="predictions">
                  {player.predictions.map((prediction, index) => (
                    <div key={index} className="prediction-card">
                      <h4>{prediction.metric}</h4>
                      <p>{prediction.value}</p>
                      <div className="visualizations">
                        <Line data={createChartData(player.predictions, player.recentPerformances, player.playerOdds, prediction.metric)} options={chartOptions} width={150} height={150} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="performances">
                  <h4>Recent Performances</h4>
                  {player.recentPerformances
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((performance, index) => (
                    <p key={index} className="centered-text">
                      {performance.date}: {performance.points} Pts, {performance.rebounds} Reb, {performance.assists} Ast, {performance.threePointMade} 3PM, {performance.points + performance.rebounds + performance.assists} PRA
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="team-ribbon">
            {homeTeam.name}
          </div>
          <div className="team-details">
            {homePlayers.map(player => (
              <div key={player.name} className="player-details">
                <h3>{player.name}</h3>
                <div className="predictions">
                  {player.predictions.map((prediction, index) => (
                    <div key={index} className="prediction-card">
                      <h4>{prediction.metric}</h4>
                      <p>{prediction.value}</p>
                      <div className="visualizations">
                        <Line data={createChartData(player.predictions, player.recentPerformances, player.playerOdds, prediction.metric)} options={chartOptions} width={150} height={150} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="performances">
                  <h4>Recent Performances</h4>
                  {player.recentPerformances
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((performance, index) => (
                    <p key={index} className="centered-text">
                      {performance.date}: {performance.points} Pts, {performance.rebounds} Reb, {performance.assists} Ast, {performance.threePointMade} 3PM, {performance.points + performance.rebounds + performance.assists} PRA
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NBAGameDetails;
