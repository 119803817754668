import React from 'react';
import '../../styles/core/Auth.css';

const TermsConditions = () => {
  return (
    <div className="auth-container">
      <div className="auth">
        <h2>Terms & Conditions</h2>
        <p>Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use.</p>

        <h3>Use of the Site</h3>
        <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>

        <h3>Proprietary Information</h3>
        <p>Sports that are subscribed to contain proprietary information meant exclusively for subscribers. This information cannot be shared, distributed, or reproduced without written consent from SportBetData.ai. If you are caught distributing information without consent, your subscription can be revoked without notice.</p>

        <h3>User Conduct</h3>
        <p>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</p>

        <h3>Governing Law</h3>
        <p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of Delaware.</p>

        <p>Please contact us if you have any questions about our terms and conditions.</p>
      </div>
    </div>
  );
};

export default TermsConditions;
