import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/data/CheatSheet.css';

const PitcherCheatSheet = () => {
  const { date } = useParams();
  const navigate = useNavigate();
  const [pitcherData, setPitcherData] = useState([]);
  const [error, setError] = useState('');
  const [expandedTeams, setExpandedTeams] = useState({});

  const handleError = useCallback((error) => {
    if (error.response) {
      if (error.response.status === 403) {
        setError('You are not subscribed to this sport.');
      } else if (error.response.status === 404) {
        setError('Sport not found.');
      } else if (error.response.data && error.response.data.code === 'token_not_valid') {
        navigate('/login');
      } else {
        setError(`An unexpected error occurred: ${error.response.statusText}`);
      }
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/pdf-data/${date}/0/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const pitcherPredictions = response.data.pitcher_predictions;

        // Remove duplicates by player name and keep the entry with the max predicted innings pitched
        const uniquePitcherData = Object.values(pitcherPredictions.reduce((acc, pitcher) => {
          if (!acc[pitcher.player_name] || acc[pitcher.player_name].predicted_innings_pitched < pitcher.predicted_innings_pitched) {
            acc[pitcher.player_name] = pitcher;
          }
          return acc;
        }, {}));

        // Sort the data by team name and then by predicted innings pitched in descending order
        uniquePitcherData.sort((a, b) => {
          if (a.player_team < b.player_team) return -1;
          if (a.player_team > b.player_team) return 1;
          return b.predicted_innings_pitched - a.predicted_innings_pitched;
        });

        setPitcherData(uniquePitcherData);
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, [date, handleError]);

  const toggleTeamExpansion = (team) => {
    setExpandedTeams((prev) => ({
      ...prev,
      [team]: !prev[team],
    }));
  };

  const calculatePredictedStrikeouts = (strikeoutRate, inningsPitched) => {
    if (strikeoutRate == null) {
      return 'Not enough data';
    }
    const predictedStrikeoutsPer9Innings = strikeoutRate * 27;
    return ((predictedStrikeoutsPer9Innings / 9) * inningsPitched).toFixed(2);
  };

  const calculatePredictedHits = (battingAverage, inningsPitched) => {
    if (battingAverage == null) {
      return 'Not enough data';
    }
    const predictedHitsPer9Innings = battingAverage * 27;
    return ((predictedHitsPer9Innings / 9) * inningsPitched).toFixed(2);
  };

  const generateVegasLine = (pitcher) => {
    const {
      strikeouts_over_under,
      earned_runs_over_under,
      hits_allowed_over_under,
      walks_issued_over_under,
    } = pitcher;

    const lines = [
      { metric: 'Strikeouts', value: strikeouts_over_under },
      { metric: 'Earned Runs', value: earned_runs_over_under },
      { metric: 'Hits Allowed', value: hits_allowed_over_under },
      { metric: 'Walks Issued', value: walks_issued_over_under },
    ];

    return lines
      .filter((line) => line.value !== null)
      .map(
        (line) =>
          `• ${line.metric}: O/U ${line.value}`
      )
      .join('\n');
  };

  const generateWriteup = (pitcher) => {
    const {
      predicted_innings_pitched,
      predicted_strikeouts_thrown,
      predicted_earned_runs,
      predicted_hits_allowed,
      predicted_walks_issued,
      opposing_team_average_runs_per_game_over_5_innings,
      opposing_team_average_runs_per_game_over_6_innings,
      opposing_team_season_batting_average,
      opposing_team_strikeout_rate,
      opposing_batting_average_against_pitcher,
      opposing_strikeout_rate_against_pitcher,
    } = pitcher;

    const vegasLine = generateVegasLine(pitcher);

    return (
      <div>
        <p><strong>Our machine learning model predicts:</strong></p>
        <ul>
          <li>Innings Pitched: {predicted_innings_pitched}</li>
          <li>Strikeouts: {predicted_strikeouts_thrown}</li>
          <li>Earned Runs: {predicted_earned_runs}</li>
          <li>Hits Allowed: {predicted_hits_allowed}</li>
          <li>Walks Issued: {predicted_walks_issued}</li>
        </ul>
        <p><strong>Opposing Team Season Averages:</strong></p>
        <ul>
          <li>Runs/Game: ~{opposing_team_average_runs_per_game_over_5_innings} over 5 innings, ~{opposing_team_average_runs_per_game_over_6_innings} over 6 innings</li>
          <li>Season Batting Avg: {opposing_team_season_batting_average}</li>
          <li>Season Strikeout Rate: {opposing_team_strikeout_rate} ({((opposing_team_strikeout_rate * 27 / 9) * 5).toFixed(2)} K over 5 IP, {((opposing_team_strikeout_rate * 27 / 9) * 6).toFixed(2)} K over 6 IP)</li>
        </ul>
        {opposing_batting_average_against_pitcher && opposing_strikeout_rate_against_pitcher && (
          <>
            <p><strong>Pitcher vs. Opposing Team:</strong></p>
            <ul>
              <li>Opposing Batting Avg: {opposing_batting_average_against_pitcher} ({calculatePredictedHits(opposing_batting_average_against_pitcher, predicted_innings_pitched)} predicted hits based on average)</li>
              <li>Opposing Strikeout Rate: {opposing_strikeout_rate_against_pitcher} ({calculatePredictedStrikeouts(opposing_strikeout_rate_against_pitcher, predicted_innings_pitched)} predicted strikeouts based on strikeout rate)</li>
            </ul>
          </>
        )}
        {vegasLine && (
          <>
            <p><strong>Vegas Line:</strong></p>
            <ul>
              {vegasLine.split('\n').map((line, index) => (
                <li key={index}>{line}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!pitcherData.length) {
    return <div>Loading...</div>;
  }

  const teams = Array.from(new Set(pitcherData.map((pitcher) => pitcher.player_team)));

  return (
    <div className="cheat-sheet">
      <div className="header">
        <h1>Pitcher Cheat Sheet for {date}</h1>
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      </div>
      <div className="team-list">
        {teams.map((team) => (
          <div key={team} className="team-section">
            <div className="team-header" onClick={() => toggleTeamExpansion(team)}>
              {team}
              <img
                src={expandedTeams[team] ? '/assets/icons/misc/collapse_icon.png' : '/assets/icons/misc/expand_icon.png'}
                alt={expandedTeams[team] ? 'Collapse' : 'Expand'}
                className="expand-collapse-icon"
              />
            </div>
            {expandedTeams[team] && (
              <div className="pitcher-list">
                {pitcherData
                  .filter((pitcher) => pitcher.player_team === team)
                  .map((pitcher) => (
                    <div key={pitcher.player_name} className="pitcher-row">
                      <div className="pitcher-header">
                        <span className="pitcher-name">{pitcher.player_name}</span> - <span className="pitcher-team">{pitcher.player_team}</span>
                      </div>
                      <div className="writeup-section">
                        {generateWriteup(pitcher)}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PitcherCheatSheet;
