import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for back button
import '../../../styles/data/CheatSheet.css'; 

const intervalOptions = [
  { value: '7', label: 'Last 7 Days' },
  { value: '14', label: 'Last 14 Days' },
  { value: '30', label: 'Last 30 Days' },
  { value: 'season', label: 'Season' },
];

const HRDuos = () => {
  const [interval, setInterval] = useState(intervalOptions[0]);
  const [hrDuos, setHrDuos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();  // Initialize navigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/retrieve-mlb-hr-duos/`,
        {
          interval: interval.value,
        }
      );
      setHrDuos(response.data);
    } catch (error) {
      setError('Failed to fetch HR duos.');
    } finally {
      setLoading(false);
    }
  };

  const renderDuos = () => {
    if (!hrDuos) return null;

    return hrDuos.map((duo, index) => (
      <div key={index} className="duo-item">
        <h4>{duo.player_combination}</h4>
        <p>Total HR Days: {duo.all_hr_days}</p>
        <ul>
          {duo.all_hr_dates.map((date, idx) => (
            <li key={idx}>{date}</li>
          ))}
        </ul>
      </div>
    ));
  };

  return (
    <div className="hr-duos-container">
      <button className="back-button" onClick={() => navigate(-1)}>
        Back
      </button>

      <h1>MLB Home Run Duos</h1>

      <form onSubmit={handleSubmit}>
        <Select
          options={intervalOptions}
          value={interval}
          onChange={(selectedOption) => setInterval(selectedOption)}
          placeholder="Select Interval"
        />
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Loading...' : 'Get HR Duos'}
        </button>
      </form>

      <div className="hr-duos-results">{renderDuos()}</div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default HRDuos;
