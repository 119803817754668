import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/data/NBAPositionMetrics.css';

const NBAPositionMetricsReport = () => {
  const [metricsData, setMetricsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [opposingDefenseFilter, setOpposingDefenseFilter] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // To navigate back

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/position-metrics-defense/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (Array.isArray(response.data.position_metrics_by_defense_vs_positions)) {
          setMetricsData(response.data.position_metrics_by_defense_vs_positions);
          setFilteredData(response.data.position_metrics_by_defense_vs_positions);
        } else {
          setMetricsData([]);
        }
        setLoading(false);
      } catch (error) {
        setError('Error fetching NBA Position Metrics data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle filtering the data by opposing defense
  const handleFilterChange = (e) => {
    const value = e.target.value;
    setOpposingDefenseFilter(value);
    if (value) {
      const filtered = metricsData.filter((item) => item.opposing_defense.toLowerCase().includes(value.toLowerCase()));
      setFilteredData(filtered);
    } else {
      setFilteredData(metricsData); // Reset to full data if filter is cleared
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="position-metrics-container">
        <h1>NBA Position Metrics vs Defense</h1>
        
        {/* Back Button */}
        <button className="back-button" onClick={() => navigate(-1)}>
            Back
        </button>
        
        {/* Explanation for the user */}
        <div className="note">
            This report shows how many points, assists, rebounds, and other metrics each opposing defense allows to players who played 25 minutes or more per game in each position. First 10 days of season will be data from previous year.
        </div>

        <br></br>
        <br></br>
        
        {/* Filter by Opposing Defense */}
        <div className="filter-container">
            <label htmlFor="opposingDefenseFilter">Filter by Opposing Defense: </label>
            <input
            type="text"
            id="opposingDefenseFilter"
            value={opposingDefenseFilter}
            onChange={handleFilterChange}
            placeholder="Enter team name"
            />
        </div>

        {/* Data Grid/Table */}
        <table className="position-metrics-table">
            <thead>
            <tr>
                <th>Opposing Defense</th>
                <th>Position</th>
                <th>Avg Points Allowed</th>
                <th>Avg Assists Allowed</th>
                <th>Avg Rebounds Allowed</th>
                <th>Avg Steals Allowed</th>
                <th>Avg Blocks Allowed</th>
                <th>Avg Turnovers Forced</th>
            </tr>
            </thead>
            <tbody>
            {filteredData.length > 0 ? (
                filteredData.map((metric, index) => (
                <tr key={index}>
                    <td>{metric.opposing_defense}</td>
                    <td>{metric.position}</td>
                    <td>{metric.avg_points}</td>
                    <td>{metric.avg_assists}</td>
                    <td>{metric.avg_rebounds}</td>
                    <td>{metric.avg_steals}</td>
                    <td>{metric.avg_blocks}</td>
                    <td>{metric.avg_turnovers}</td>
                </tr>
                ))
            ) : (
                <tr>
                <td colSpan="9">No data available.</td>
                </tr>
            )}
            </tbody>
        </table>
        </div>
  );
};

export default NBAPositionMetricsReport;
