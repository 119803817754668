/* 
# ##################################################################################################
# Imports
# ##################################################################################################
*/

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../styles/core/UserSettings.css';

/* 
# ##################################################################################################
# Core Logic
# ##################################################################################################
*/

const UserSettings = () => {
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: ''
  });

  const [passwordInfo, setPasswordInfo] = useState({
    current_password: '',
    new_password: '',
    confirm_password: ''
  });

  const [originalUserInfo, setOriginalUserInfo] = useState({});
  const [message, setMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmType, setConfirmType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        navigate('/login');
      } else {
        try {
          const response = await axios.get(`${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_USER_API_TYPE}user-info/`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setUserInfo(response.data);
          setOriginalUserInfo(response.data);
          setLoading(false);
        } catch (error) {
          navigate('/login');
        }
      }
    };

    checkAuthentication();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordInfo({ ...passwordInfo, [name]: value });
  };

  const handleUserInfoSubmit = (e) => {
    e.preventDefault();
    setConfirmType('userInfo');
    setShowConfirmModal(true);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    setConfirmType('password');
    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    const token = localStorage.getItem('accessToken');
    setShowConfirmModal(false);
    if (confirmType === 'userInfo') {
      const updatedInfo = Object.keys(userInfo).reduce((acc, key) => {
        if (userInfo[key] !== originalUserInfo[key]) {
          acc[key] = userInfo[key];
        }
        return acc;
      }, {});

      try {
        if (Object.keys(updatedInfo).length > 0) {
          await axios.put(`${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_USER_API_TYPE}update-user/`, updatedInfo, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setMessage('User info updated successfully.');
          setOriginalUserInfo(userInfo);
        }
      } catch (error) {
        setMessage('Failed to update user info.');
      }
    } else if (confirmType === 'password') {
      try {
        if (passwordInfo.new_password && passwordInfo.new_password === passwordInfo.confirm_password) {
          await axios.put(`${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_USER_API_TYPE}update-password/`, {
            current_password: passwordInfo.current_password,
            new_password: passwordInfo.new_password,
            confirm_password: passwordInfo.confirm_password,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setPasswordMessage('Password updated successfully.');
          setPasswordInfo({
            current_password: '',
            new_password: '',
            confirm_password: ''
          });
        } else {
          setPasswordMessage('New password and confirm password do not match.');
        }
      } catch (error) {
        setPasswordMessage('Failed to update password. Incorrect current password.');
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-settings-wrapper">
      <button className="home-button" onClick={() => navigate('/dashboard')}>Home</button>
      <div className="user-settings-container">
        <h2>User Settings</h2>
        <form onSubmit={handleUserInfoSubmit}>
          <div className="form-group">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={userInfo.first_name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={userInfo.last_name}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Update</button>
          {message && <p className="message">{message}</p>}
        </form>

        <div className="divider"></div>

        <h2>Change Password</h2>
        <form onSubmit={handlePasswordSubmit}>
          <div className="form-group">
            <label htmlFor="current_password">Current Password</label>
            <input
              type="password"
              id="current_password"
              name="current_password"
              value={passwordInfo.current_password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="new_password">New Password</label>
            <input
              type="password"
              id="new_password"
              name="new_password"
              value={passwordInfo.new_password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirm_password">Confirm New Password</label>
            <input
              type="password"
              id="confirm_password"
              name="confirm_password"
              value={passwordInfo.confirm_password}
              onChange={handlePasswordChange}
            />
          </div>
          <button type="submit">Change Password</button>
          {passwordMessage && <p className="message">{passwordMessage}</p>}
        </form>

        {showConfirmModal && (
          <div className="confirm-modal">
            <div className="modal-content">
              <h3>Confirm Changes</h3>
              <p>Are you sure you want to update your information?</p>
              <button onClick={handleConfirm}>Yes</button>
              <button onClick={() => setShowConfirmModal(false)}>No</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSettings;

/* 
# ##################################################################################################
# END
# ##################################################################################################
*/
