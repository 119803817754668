import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import '../../styles/core/GenerateSlipForm.css';

const GenerateSlipForm = () => {
  const [riskTolerance, setRiskTolerance] = useState('LOW');
  const [amountOfPicks, setAmountOfPicks] = useState('');
  const [numSlips, setNumSlips] = useState('');
  const [excludePlayerPicks, setExcludePlayerPicks] = useState([]);
  const [excludeGamePicks, setExcludeGamePicks] = useState([]);
  const [excludeBetTypes, setExcludeBetTypes] = useState([]);
  const [includePlayerPicks, setIncludePlayerPicks] = useState([]);
  const [includeGamePicks, setIncludeGamePicks] = useState([]);
  const [includeBetTypes, setIncludeBetTypes] = useState([]);
  const [mlbPlayerPicks, setMlbPlayerPicks] = useState([]);
  const [wnbaPlayerPicks, setWnbaPlayerPicks] = useState([]);
  const [nflPlayerPicks, setNflPlayerPicks] = useState([]);  // Added NFL picks
  const [mlbGamePicks, setMlbGamePicks] = useState([]);
  const [wnbaGamePicks, setWnbaGamePicks] = useState([]);
  const [nflGamePicks, setNflGamePicks] = useState([]);  // Added NFL game picks
  const [sportsOptions, setSportsOptions] = useState([]);
  const [message, setMessage] = useState('');
  const [generatedSlips, setGeneratedSlips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPicksOptions = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const estDate = moment().tz('America/New_York').format('YYYY-MM-DD');

        const sportsResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}view-active-user-subscriptions/`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSportsOptions(sportsResponse.data);

        let mlbPlayerPicks = [];
        let wnbaPlayerPicks = [];
        let nflPlayerPicks = [];  // Added NFL player picks
        let mlbGamePicks = [];
        let wnbaGamePicks = [];
        let nflGamePicks = [];  // Added NFL game picks

        for (const sport of sportsResponse.data) {
          if (sport.sport === 'MLB') {
            const playerResponse = await axios.get(
              `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/player-odds/${estDate}/`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            mlbPlayerPicks = [...mlbPlayerPicks, ...playerResponse.data];

            const gameResponse = await axios.get(
              `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/game-odds/${estDate}/`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            mlbGamePicks = [...mlbGamePicks, ...gameResponse.data];
          } else if (sport.sport === 'WNBA') {
            const playerResponse = await axios.get(
              `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}wnba/player-odds/${estDate}/`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            wnbaPlayerPicks = [...wnbaPlayerPicks, ...playerResponse.data];

            const gameResponse = await axios.get(
              `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}wnba/game-odds/${estDate}/`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            wnbaGamePicks = [...wnbaGamePicks, ...gameResponse.data];
          } else if (sport.sport === 'NFL') {  // Added NFL picks
            const playerResponse = await axios.get(
              `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/player-odds/${estDate}/`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            nflPlayerPicks = [...nflPlayerPicks, ...playerResponse.data];

            const gameResponse = await axios.get(
              `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/game-odds/${estDate}/`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            nflGamePicks = [...nflGamePicks, ...gameResponse.data];
          }
        }

        setMlbPlayerPicks(mlbPlayerPicks);
        setWnbaPlayerPicks(wnbaPlayerPicks);
        setNflPlayerPicks(nflPlayerPicks);  // Set NFL player picks
        setMlbGamePicks(mlbGamePicks);
        setWnbaGamePicks(wnbaGamePicks);
        setNflGamePicks(nflGamePicks);  // Set NFL game picks
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setSubscriptionError(true);
          setMessage('You do not have access to view any sports. Please purchase a subscription first.');
        } else {
          setMessage('Failed to load picks options.');
        }
      }
    };

    fetchPicksOptions();
  }, []);

  const toggleCollapse = (e) => {
    const content = e.currentTarget.nextElementSibling;
    content.classList.toggle('show');
  };

  const handleExcludePlayerPickChange = (pickId) => {
    setExcludePlayerPicks((prev) =>
      prev.includes(pickId) ? prev.filter((id) => id !== pickId) : [...prev, pickId]
    );
  };

  const handleIncludePlayerPickChange = (pickId) => {
    setIncludePlayerPicks((prev) =>
      prev.includes(pickId) ? prev.filter((id) => id !== pickId) : [...prev, pickId]
    );
  };

  const handleExcludeGamePickChange = (gameId) => {
    setExcludeGamePicks((prev) =>
      prev.includes(gameId) ? prev.filter((id) => id !== gameId) : [...prev, gameId]
    );
  };

  const handleIncludeGamePickChange = (gameId) => {
    setIncludeGamePicks((prev) =>
      prev.includes(gameId) ? prev.filter((id) => id !== gameId) : [...prev, gameId]
    );
  };

  const handleExcludeBetTypeChange = (betType) => {
    setExcludeBetTypes((prev) =>
      prev.includes(betType) ? prev.filter((type) => type !== betType) : [...prev, betType]
    );
  };

  const handleIncludeBetTypeChange = (betType) => {
    setIncludeBetTypes((prev) =>
      prev.includes(betType) ? prev.filter((type) => type !== betType) : [...prev, betType]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    setGeneratedSlips([]);
    setSubscriptionError(false);

    const token = localStorage.getItem('accessToken');

    const payload = {
      risk_tolerance: riskTolerance,
      amount_of_picks: amountOfPicks,
      num_slips: numSlips,
      include_player_picks: includePlayerPicks,
      include_game_picks: includeGamePicks,
      include_bet_types: includeBetTypes,
      exclude_player_picks: excludePlayerPicks,
      exclude_game_picks: excludeGamePicks,
      exclude_bet_types: excludeBetTypes,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}generate-slip/`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.slips.length === 0) {
        setMessage('No slips were generated. Please readjust your criteria and try again.');
      } else {
        setMessage('Slip(s) generated successfully.');
      }

      setGeneratedSlips(response.data.slips);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSubscriptionError(true);
        setMessage('You do not have access to generate slips. Please purchase a subscription first.');
      } else if (error.response && error.response.data && error.response.data.error) {
        setMessage(error.response.data.error);
      } else {
        setMessage('Failed to generate slip.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderIncludeExcludeControls = (
    options,
    handleExcludeChange,
    handleIncludeChange,
    excludeList,
    includeList
  ) => (
    <div className="form-group collapse-section">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {options.length > 0 ? (
        options.map((option) => (
          <div key={option.id} className="metric-item">
            <div className="metric-text">
              {option.player_name || option.game_display_name} {option.metric || ''}
            </div>
            <div className="metric-controls">
              <input
                type="checkbox"
                checked={excludeList.includes(option.id)}
                onChange={() => handleExcludeChange(option.id)}
                disabled={includeList.includes(option.id)}
              />
              Exclude
              <input
                type="checkbox"
                checked={includeList.includes(option.id)}
                onChange={() => handleIncludeChange(option.id)}
                disabled={excludeList.includes(option.id)}
              />
              Include
            </div>
          </div>
        ))
      ) : (
        <p>No options available</p>
      )}
    </div>
  );

  const handleShowPdf = (gameId, sportAbbreviation) => {
    const today = moment().tz('America/New_York').format('YYYY-MM-DD');
    window.open(`/game-details/${sportAbbreviation}/${today}/${gameId}`, '_blank');
  };

  const getMaxPicks = () => {
    switch (riskTolerance) {
      case 'HIGH':
        return 2;
      case 'MEDIUM':
        return 3;
      case 'LOW':
      default:
        return 4;
    }
  };

  const amountOfPicksOptions = Array.from({ length: getMaxPicks() }, (_, i) => i + 1);

  return (
    <div className="page-container">
      <button className="home-button" onClick={() => navigate('/dashboard')}>
        Home
      </button>
      <div className="generate-slip-form-container">
        <h2>Generate Betting Slip(s)</h2>
        {message && (
          <p className={`message ${subscriptionError ? 'message-error' : 'message-success'}`}>
            {message}
          </p>
        )}
        <div className="rules-container">
          <p>Rules for Bet Generation:</p>
          <ul>
            <li>Odds must be populated. Typically populated around 9AM EST.</li>
            <li>Props generated are based on events that have not taken place.</li>
            <li>Props only generate for sports you are subscribed to.</li>
            <li>Inclusion/Exclusion is completely optional.</li>
            <li>If you include an item, you cannot exclude it and vice versa.</li>
            <li>
              Inclusion places a bet in the pool of selections, but does not guarantee it shows on
              every slip.
            </li>
            <li>Use the search bar to quickly find specific players, games, or bet types.</li>
            <li>Slip generation takes longer for higher amount of slip picks.</li>
            <li>
              The slip generator only will output bets it has confidence in or bets where we truly
              like the odds.
            </li>
            <li>If a slip fails to generate, adjust your criteria!</li>
          </ul>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="riskTolerance">Risk Tolerance</label>
            <select
              id="riskTolerance"
              value={riskTolerance}
              onChange={(e) => setRiskTolerance(e.target.value)}
              required
            >
              {['LOW', 'MEDIUM', 'HIGH'].map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="amountOfPicks">Amount of Picks in Slip</label>
            <select
              id="amountOfPicks"
              value={amountOfPicks}
              onChange={(e) => setAmountOfPicks(e.target.value)}
              required
            >
              {amountOfPicksOptions.map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="numSlips">Number of Slips to Generate</label>
            <select
              id="numSlips"
              value={numSlips}
              onChange={(e) => setNumSlips(e.target.value)}
              required
            >
              {Array.from({ length: 3 }, (_, i) => i + 1).map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
          </div>

          {sportsOptions.length > 0 ? (
            sportsOptions.map((sport) => (
              <div key={sport.sport_id} className="form-group collapse-section">
                <div className="collapse-header" onClick={toggleCollapse}>
                  {sport.sport} Picks +
                </div>
                <div className="collapse-content">
                  {sport.sport === 'MLB' && (
                    <>
                      <div className="form-group collapse-section">
                        <div className="collapse-header" onClick={toggleCollapse}>
                          Exclude/Include Player Picks +
                        </div>
                        <div className="collapse-content">
                          {renderIncludeExcludeControls(
                            mlbPlayerPicks.map((pick) => ({
                              id: pick.mlb_player_odd_id,
                              player_name: pick.player_name,
                              metric: pick.metric,
                            })),
                            handleExcludePlayerPickChange,
                            handleIncludePlayerPickChange,
                            excludePlayerPicks,
                            includePlayerPicks
                          )}
                        </div>
                      </div>
                      <div className="form-group collapse-section">
                        <div className="collapse-header" onClick={toggleCollapse}>
                          Exclude/Include Game Picks +
                        </div>
                        <div className="collapse-content">
                          {renderIncludeExcludeControls(
                            mlbGamePicks.map((pick) => ({
                              id: pick.mlb_odd_id,
                              game_display_name: pick.game_display_name,
                            })),
                            handleExcludeGamePickChange,
                            handleIncludeGamePickChange,
                            excludeGamePicks,
                            includeGamePicks
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {sport.sport === 'WNBA' && (
                    <>
                      <div className="form-group collapse-section">
                        <div className="collapse-header" onClick={toggleCollapse}>
                          Exclude/Include Player Picks +
                        </div>
                        <div className="collapse-content">
                          {renderIncludeExcludeControls(
                            wnbaPlayerPicks.map((pick) => ({
                              id: pick.wnba_player_odd_id,
                              player_name: pick.player_name,
                              metric: pick.metric,
                            })),
                            handleExcludePlayerPickChange,
                            handleIncludePlayerPickChange,
                            excludePlayerPicks,
                            includePlayerPicks
                          )}
                        </div>
                      </div>
                      <div className="form-group collapse-section">
                        <div className="collapse-header" onClick={toggleCollapse}>
                          Exclude/Include Game Picks +
                        </div>
                        <div className="collapse-content">
                          {renderIncludeExcludeControls(
                            wnbaGamePicks.map((pick) => ({
                              id: pick.wnba_odd_id,
                              game_display_name: pick.game_display_name,
                            })),
                            handleExcludeGamePickChange,
                            handleIncludeGamePickChange,
                            excludeGamePicks,
                            includeGamePicks
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {sport.sport === 'NFL' && (  // Added NFL player and game picks
                    <>
                      <div className="form-group collapse-section">
                        <div className="collapse-header" onClick={toggleCollapse}>
                          Exclude/Include Player Picks +
                        </div>
                        <div className="collapse-content">
                          {renderIncludeExcludeControls(
                            nflPlayerPicks.map((pick) => ({
                              id: pick.nfl_player_odd_id,
                              player_name: pick.player_name,
                              metric: pick.metric,
                            })),
                            handleExcludePlayerPickChange,
                            handleIncludePlayerPickChange,
                            excludePlayerPicks,
                            includePlayerPicks
                          )}
                        </div>
                      </div>
                      <div className="form-group collapse-section">
                        <div className="collapse-header" onClick={toggleCollapse}>
                          Exclude/Include Game Picks +
                        </div>
                        <div className="collapse-content">
                          {renderIncludeExcludeControls(
                            nflGamePicks.map((pick) => ({
                              id: pick.nfl_odd_id,
                              game_display_name: pick.game_display_name,
                            })),
                            handleExcludeGamePickChange,
                            handleIncludeGamePickChange,
                            excludeGamePicks,
                            includeGamePicks
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group collapse-section">
                    <div className="collapse-header" onClick={toggleCollapse}>
                      Exclude/Include Bet Types +
                    </div>
                    <div className="collapse-content">
                      {renderIncludeExcludeControls(
                        sport.sport === 'MLB'
                          ? [
                              { id: 'hits_over', metric: 'Hits Over' },
                              { id: 'hits_under', metric: 'Hits Under' },
                              { id: 'home_runs_over', metric: 'Home Runs Over' },
                              { id: 'home_runs_under', metric: 'Home Runs Under' },
                              { id: 'strikeouts_over', metric: 'Strikeouts Over' },
                              { id: 'strikeouts_under', metric: 'Strikeouts Under' },
                              { id: 'earned_runs_over', metric: 'Earned Runs Over' },
                              { id: 'earned_runs_under', metric: 'Earned Runs Under' },
                              { id: 'total_runs_over', metric: 'Total Runs Over' },
                              { id: 'total_runs_under', metric: 'Total Runs Under' },
                              { id: 'outs_over', metric: 'Outs Over' },
                              { id: 'outs_under', metric: 'Outs Under' }
                            ]
                          : sport.sport === 'WNBA'
                          ? [
                              { id: 'points_over', metric: 'Points Over' },
                              { id: 'points_under', metric: 'Points Under' },
                              { id: 'rebounds_over', metric: 'Rebounds Over' },
                              { id: 'rebounds_under', metric: 'Rebounds Under' },
                              { id: 'assists_over', metric: 'Assists Over' },
                              { id: 'assists_under', metric: 'Assists Under' },
                              { id: 'total_points_over', metric: 'Total Points Over' },
                              { id: 'total_points_under', metric: 'Total Points Under' }
                            ]
                          : sport.sport === 'NFL'  // Added NFL bet types
                          ? [
                              { id: 'passing_yards_over', metric: 'Passing Yards Over' },
                              { id: 'passing_yards_under', metric: 'Passing Yards Under' },
                              { id: 'rushing_yards_over', metric: 'Rushing Yards Over' },
                              { id: 'rushing_yards_under', metric: 'Rushing Yards Under' },
                              { id: 'receiving_yards_over', metric: 'Receiving Yards Over' },
                              { id: 'receiving_yards_under', metric: 'Receiving Yards Under' },
                              { id: 'touchdowns_over', metric: 'Touchdowns Over' },
                              { id: 'touchdowns_under', metric: 'Touchdowns Under' }
                            ]
                          : [],
                        handleExcludeBetTypeChange,
                        handleIncludeBetTypeChange,
                        excludeBetTypes,
                        includeBetTypes
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No sports available</p>
          )}
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Generating...' : 'Generate Slip(s)'}
          </button>
        </form>
      </div>
      {isLoading && (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      )}
      {subscriptionError ? (
        <div className="subscription-error">
          <p>You do not have access to view any sports or generate slips. Please purchase a subscription first.</p>
        </div>
      ) : (
        <div className="slips-container">
          {generatedSlips.map((slip, index) => (
            <div key={index} className="slip">
              <h3>Slip {index + 1}</h3>
              {slip.bets.map((bet, betIndex) => (
                <div key={betIndex} className="slip-leg">
                  <p>{`${bet.type} ${bet.name} - ${bet.metric ? `${bet.metric} ${bet.threshold}` : ''} | ${bet.bet_type}`}</p>
                  <button onClick={() => handleShowPdf(bet.game_id, bet.sport_abbreviation)}>
                    <img src="/assets/icons/misc/pdf_icon.png" alt="Show Details" className="icon" />
                  </button>
                </div>
              ))}
              <hr className="separator" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GenerateSlipForm;
