/* 
# ##################################################################################################
# Imports
# ##################################################################################################
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../styles/core/FileIssue.css';

/* 
# ##################################################################################################
# Core Logic
# ##################################################################################################
*/

const FileIssue = () => {
  const [issueType, setIssueType] = useState('');
  const [issueDetail, setIssueDetail] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        navigate('/login');
      } else {
        try {
          await axios.get(`${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}check-auth/`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setLoading(false);
        } catch (error) {
          navigate('/login');
        }
      }
    };

    checkAuthentication();
  }, [navigate]);

  const handleFileChange = (e) => {
    setAttachments(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('issue_type', issueType);
    formData.append('issue_detail', issueDetail);

    Array.from(attachments).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    const token = localStorage.getItem('accessToken');

    try {
      await axios.post(`${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}file-issue/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      setMessage('Issue submitted successfully.');
    } catch (error) {
      setMessage('Failed to submit issue.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="file-issue-container">
      <h2>File an Issue</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="issueType">Issue Type</label>
          <select
            id="issueType"
            value={issueType}
            onChange={(e) => setIssueType(e.target.value)}
            required
          >
            <option value="">Select an issue</option>
            <option value="subscription">Subscription Issue</option>
            <option value="payment">Payment Issue</option>
            <option value="technical">Technical Issue</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="issueDetail">Issue Detail</label>
          <textarea
            id="issueDetail"
            value={issueDetail}
            onChange={(e) => setIssueDetail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="attachments">Attachments (optional)</label>
          <input
            type="file"
            id="attachments"
            multiple
            onChange={handleFileChange}
          />
        </div>
        <button type="submit">Submit</button>
        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

export default FileIssue;

/* 
# ##################################################################################################
# END
# ##################################################################################################
*/
