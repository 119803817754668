/*
########################################################################
# Imports & Constants
########################################################################
*/

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../api/authApi';
import '../../styles/core/Auth.css';

/*
########################################################################
# Core Logic
########################################################################
*/

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(email);
      setMessage('Password reset link has been sent to your email.');
    } catch (error) {
      setMessage('Failed to send password reset link.');
    }
  };

  const handleLogoClick = () => {
      navigate('/');
  };

  return (
    <div className="auth-container">
      <div className="auth-logo" onClick={handleLogoClick}>
        <img src="/assets/images/sportbetdata_full_logo.png" alt="Sport Bet Data Logo" />
      </div>
      <div className="auth">
        <h2>Forgot Password</h2>
        <p>Enter your email address to reset your password.</p>
        <form onSubmit={handleForgotPassword}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default ForgotPassword;

/*
########################################################################
# END
########################################################################
*/
