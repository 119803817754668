import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { registerUser } from '../../api/authApi';
import '../../styles/core/Auth.css';

const Register = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    dob: '',
    password: '',
    confirm_password: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCode = params.get('referral_code');
    if (referralCode) {
      localStorage.setItem('referral_code', referralCode);
    }
  }, [location]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const referral_code = localStorage.getItem('referral_code');
      await registerUser({ ...formData, referral_code });
      navigate('/confirm-email', { state: { email: formData.email } });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="auth-container">
      <div className="auth-logo" onClick={handleLogoClick}>
        <img src="/assets/images/sportbetdata_full_logo.png" alt="Sport Bet Data Logo" />
      </div>
      <div className="auth">
        <h2>Register</h2>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Date of Birth</label>
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Register</button>
        </form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <div className="auth-links">
          <p>
            By registering, you agree to our{' '}
            <Link to="/privacy-policy" target="_blank">privacy policy</Link> and{' '}
            <Link to="/terms-conditions" target="_blank">terms & conditions</Link>.
          </p>
          <p>
            Already have an account? <Link to="/login">Sign in</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
