import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/core/Subscribe.css';

const Subscribe = ({ onClose }) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('credit-card');
  const [promoCode, setPromoCode] = useState('');
  const [promoMessage, setPromoMessage] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        navigate('/login');
      } else {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}get-potential-subscriber-subscription-details/`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSubscriptionDetails(response.data);
        } catch (error) {
          navigate('/login');
        }
      }
    };

    fetchSubscriptionDetails();
  }, [navigate]);

  const handlePlanSelect = (plan, subscriptionId) => {
    setSelectedPlan(plan);
    setSelectedSubscriptionId(subscriptionId);
    setMessage('');
    setIsError(false);
  };

  const handlePromoCodeSubmit = async () => {
    const token = localStorage.getItem('accessToken');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}validate-promo-code/`,
        { promo_code: promoCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPromoMessage(response.data.message);
      setIsError(false);

      if (response.status === 200 && response.data.message.includes('Promo code applied successfully.')) {
        window.location.reload();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Failed to apply promo code.';
      setPromoMessage(errorMessage);
      setIsError(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      setMessage('Stripe has not been loaded.');
      setIsError(true);
      return;
    }

    if (!selectedSubscriptionId) {
      setMessage('Please select a subscription plan.');
      setIsError(true);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setMessage('Please enter your card details.');
      setIsError(true);
      return;
    }

    setIsLoading(true);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setMessage(error.message);
        setIsError(true);
        setIsLoading(false);
        return;
      }

      const formData = {
        subscription_id: selectedSubscriptionId,
        payment_method_id: paymentMethod.id,
      };

      const token = localStorage.getItem('accessToken');

      const response = await axios.post(
        `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}purchase-subscription/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage(response.data.message);
      setIsError(false);
      window.location.reload();
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Failed to purchase subscription.';
      setMessage(errorMessage);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (!subscriptionDetails) {
    return <div>Loading...</div>;
  }

  const monthlyCost = subscriptionDetails.monthly_cost?.toFixed(2);
  const yearlyCost = subscriptionDetails.yearly_cost?.toFixed(2);
  const oneDayPassValue = subscriptionDetails.one_day_pass_value?.toFixed(2);

  return (
    <div className="subscribe-modal">
      <div className="subscribe-container">
        <div className="price-details">
          {oneDayPassValue && (
            <div
              className={`price-item ${selectedPlan === 'day' ? 'selected' : ''}`}
              onClick={() => handlePlanSelect('day', subscriptionDetails.one_day_pass_value_id)}
            >
              <div className="price">${oneDayPassValue}</div>
              <div className="description">1-Day Pass</div>
            </div>
          )}
          {monthlyCost && (
            <div
              className={`price-item ${selectedPlan === 'monthly' ? 'selected' : ''}`}
              onClick={() => handlePlanSelect('monthly', subscriptionDetails.monthly_subscription_id)}
            >
              <div className="price">${monthlyCost}</div>
              <div className="description">Monthly Subscription</div>
            </div>
          )}
          {yearlyCost && (
            <div
              className={`price-item ${selectedPlan === 'yearly' ? 'selected' : ''}`}
              onClick={() => handlePlanSelect('yearly', subscriptionDetails.yearly_subscription_id)}
            >
              <div className="price">${yearlyCost}</div>
              <div className="description">Yearly Subscription</div>
            </div>
          )}
        </div>

        <div className="promo-code-container">
          <input
            type="text"
            placeholder="Enter promo code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            className="promo-code-input"
          />
          <button onClick={handlePromoCodeSubmit} className="apply-promo-button">
            Apply
          </button>
          {promoMessage && <p className={`promo-message ${isError ? 'promo-message-error' : 'promo-message-success'}`}>{promoMessage}</p>}
        </div>

        <div className="payment-container">
          <div className="transaction-amount">
            Transaction Amount: ${selectedPlan === 'monthly' ? monthlyCost : selectedPlan === 'yearly' ? yearlyCost : oneDayPassValue}
          </div>
          <div className="non-recurring-message">
            Note: This is a one-time payment and does not automatically renew. Come back anytime to renew your subscription!
          </div>
          <form onSubmit={handleSubmit}>
            <div className="payment-method">
              <div className="payment-option">
                <input
                  type="radio"
                  name="payment-method"
                  value="credit-card"
                  checked={selectedPaymentMethod === 'credit-card'}
                  onChange={() => setSelectedPaymentMethod('credit-card')}
                /> Credit Card
              </div>
            </div>
            <div className="card-brands">
              <img src="/assets/images/amex.png" alt="American Express" />
              <img src="/assets/images/mastercard.png" alt="MasterCard" />
              <img src="/assets/images/visa.png" alt="Visa" />
            </div>
            {selectedPaymentMethod === 'credit-card' && (
              <CardElement className="input-field" />
            )}
            <button
              type="submit"
              disabled={isLoading || !selectedSubscriptionId || !stripe || !elements}
              className="pay-button"
            >
              {isLoading ? 'Processing...' : 'Pay'}
            </button>
            {message && <p className={`message ${isError ? 'message-error' : 'message-success'}`}>{message}</p>}
          </form>
          <button className="close-button" onClick={onClose}>Close</button>
        </div>

        <div className="disclaimer">
          Payments are final. If we ever have any outages, we prorate refunds.
        </div>
      </div>

      {isLoading && (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default Subscribe;
