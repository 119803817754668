// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --background-color-body: #FFFFFF;
    --font-family-main: 'Open Sans Condensed', sans-serif;
    --text-color-main: #545252;
}

* {
    box-sizing: border-box;
    margin: 0;
    overflow: visible;
}

body {
    background-color: var(--background-color-body);
    font-family: var(--font-family-main);
    color: var(--text-color-main);
}

.margin-sm {
    margin: 8px;
}
.margin-md {
    margin: 16px;
}
.margin-lg {
    margin: 32px;
}

.text-standard {
    font-size: 28px;
}

@media (max-width: 768px) {
    .styling-white-separator {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/global/global.css"],"names":[],"mappings":"AAEA;IACI,gCAAgC;IAChC,qDAAqD;IACrD,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;IACtB,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,8CAA8C;IAC9C,oCAAoC;IACpC,6BAA6B;AACjC;;AAEA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap');\n\n:root {\n    --background-color-body: #FFFFFF;\n    --font-family-main: 'Open Sans Condensed', sans-serif;\n    --text-color-main: #545252;\n}\n\n* {\n    box-sizing: border-box;\n    margin: 0;\n    overflow: visible;\n}\n\nbody {\n    background-color: var(--background-color-body);\n    font-family: var(--font-family-main);\n    color: var(--text-color-main);\n}\n\n.margin-sm {\n    margin: 8px;\n}\n.margin-md {\n    margin: 16px;\n}\n.margin-lg {\n    margin: 32px;\n}\n\n.text-standard {\n    font-size: 28px;\n}\n\n@media (max-width: 768px) {\n    .styling-white-separator {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
