import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/core/Home.css';
import FAQ from '../global/FAQ';
import faqData from '../misc/faqData'; 

const Home = () => {
  return (
    <div className="home">
      <img src="/assets/images/sportbetdata_full_logo.png" alt="Sport Bet Data Logo" className="logo" />
      <div className="buttons">
        <Link to="/login" className="button">Login</Link>
        <Link to="/register" className="button">Register</Link>
        <Link to="/sample-data-page" className="button">View Cheat Sheets from Our Data</Link>
      </div>
      <FAQ faqData={faqData} />
    </div>
  );
};

export default Home;
