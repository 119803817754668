import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import '../../styles/core/Sport.css';

// CheatSheetDropdown component
const CheatSheetDropdown = ({ sport_id, date }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();

  const baseballOptions = [
    { value: 'pitcher-cheat-sheet', label: 'Pitcher Cheat Sheet' },
    { value: 'hr-drought-cheat-sheet', label: 'HR Droughts' },
    { value: 'hit-drought-cheat-sheet', label: 'Hit Droughts' },
    { value: 'hot-batter-cheat-sheet', label: 'Hot Batters with Good History' },
    { value: 'good-history-cheat-sheet', label: 'Batters with Good History' },
    { value: 'hr-drought-good-history-batter-cheat-sheet', label: 'HR Drought Batters with Good History' },
    { value: 'hit-drought-good-history-batter-cheat-sheet', label: 'Hit Drought Batters with Good History' },
    { value: 'mlb-trend-history-cheat-sheet', label: 'Pitcher Trend Report' },
  ];

  const wnbaOptions = [
    { value: 'wnba-trend-history-cheat-sheet', label: 'Trend History Cheat Sheet' },
  ];

  const nflOptions = [
    { value: 'player-cheat-sheet', label: 'Player Cheat Sheet' },
    { value: 'nfl-trend-history-cheat-sheet', label: 'Trend History Cheat Sheet' },
    { value: 'nfl-defense-position-td-cheat-sheet', label: 'Defense Allowed TD Metrics' },
    { value: 'nfl-cluster-report', label: 'Cluster Report' },
  ];

  const nbaOptions = [
    { value: 'nba-trend-history-cheat-sheet', label: 'Trend History Cheat Sheet' },
    { value: 'nba-position-metrics-report', label: 'Position Metrics Report' },
    { value: 'nba-cluster-report', label: 'PPG Cluster Report' },
    { value: 'nba-player-opposing-defense-report', label: 'Player Opposing Defense Report' },
  ];

  // Determine which cheat sheets to show based on sport_id
  const options = sport_id === '1' ? baseballOptions
                : sport_id === '2' ? nflOptions
                : sport_id === '3' ? nbaOptions
                : sport_id === '4' ? wnbaOptions
                : [];

  const handleNavigate = () => {
    if (selectedOption) {
      // NFL, NBA, and WNBA do not need a date in the URL, so build the URL accordingly
      const url = sport_id === '2' || sport_id === '3' || sport_id === '4'
        ? `/${selectedOption}/${sport_id}/` // No date for NFL, NBA, and WNBA
        : `/${selectedOption}/${sport_id}/${date}`; // Include date for MLB
      navigate(url);
    }
  };

  return (
    <div className="cheat-sheet-dropdown">
      <select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        className="dropdown"
        disabled={options.length === 0}
      >
        <option value="" disabled>
          {options.length === 0 ? 'No Cheat Sheets Available' : 'Select Cheat Sheet'}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <button
        onClick={handleNavigate}
        className="navigate-button"
        disabled={!selectedOption || options.length === 0}
      >
        Go
      </button>
    </div>
  );
};

// Main Sport component
const Sport = () => {
  const { sport_id } = useParams();
  const [dates, setDates] = useState({});
  const [error, setError] = useState('');
  const [expandedDates, setExpandedDates] = useState({});
  const [sportName, setSportName] = useState('');
  const [loading, setLoading] = useState(true);
   // eslint-disable-next-line
  const [showNoPredictionsMessage, setShowNoPredictionsMessage] = useState(false);
  const [canViewTomorrow, setCanViewTomorrow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkPermissionsAndFetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const permissionResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}check-sport-permissions/${sport_id}/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (permissionResponse.status === 200) {
          setCanViewTomorrow(permissionResponse.data.can_view_tomorrow);

          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}sports/${sport_id}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
              params: { include_tomorrow: permissionResponse.data.can_view_tomorrow },
            }
          );

          const data = response.data;
          setSportName(data.length > 0 ? data[0].sport_name : 'Sport');

          const groupedDates = data.reduce((acc, game) => {
            if (!acc[game.game_date]) {
              acc[game.game_date] = [];
            }
            acc[game.game_date].push(game);
            return acc;
          }, {});

          const sortedDates = Object.keys(groupedDates)
            .sort((a, b) => new Date(a) - new Date(b))
            .reduce((obj, key) => {
              obj[key] = groupedDates[key];
              return obj;
            }, {});

          const allExpanded = Object.keys(sortedDates).reduce((acc, date) => {
            acc[date] = true;
            return acc;
          }, {});

          setDates(sortedDates);
          setExpandedDates(allExpanded);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403) {
            setError('You are not subscribed to this sport.');
          } else if (error.response.status === 404) {
            setError('Sport not found.');
          } else if (error.response.data && error.response.data.code === 'token_not_valid') {
            navigate('/login');
          } else {
            setError(`An unexpected error occurred: ${error.response.statusText}`);
          }
        } else if (error.request) {
          setError('No response received from the server.');
        } else {
          setError(`An unexpected error occurred: ${error.message}`);
        }
      } finally {
        setLoading(false);
      }
    };

    checkPermissionsAndFetchData();

    const timeoutId = setTimeout(() => {
      setShowNoPredictionsMessage(true);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [sport_id, navigate]);

  const handleGenerateExcel = async (date) => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(
        `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}generate-excel/`,
        { game_date: date, sport_id: sport_id },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        }
      );
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${sportName}_Game_Data_${date}.xlsx`);
    } catch (error) {
      if (error.response) {
        setError(`Failed to generate Excel: ${error.response.statusText}`);
      } else if (error.request) {
        setError('Failed to generate Excel: No response received from the server.');
      } else {
        setError(`Failed to generate Excel: ${error.message}`);
      }
    }
  };

  const handleShowPdf = (date, game_id) => {
    navigate(`/game-details/${sportName.toLowerCase()}/${date}/${game_id}`);
  };

  const toggleDateExpansion = (date) => {
    setExpandedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  const today = moment.tz('America/New_York').format('YYYY-MM-DD');
  const oneDayAgo = moment.tz('America/New_York').subtract(1, 'days').format('YYYY-MM-DD');
  const oneWeekAhead = moment.tz('America/New_York').add(7, 'days').format('YYYY-MM-DD');

  // Get future dates (up to 7 days ahead) and today's date
  const upcomingDates = canViewTomorrow
    ? Object.keys(dates).filter((date) => moment(date).isSameOrAfter(today) && moment(date).isBefore(oneWeekAhead))
    : Object.keys(dates).filter((date) => moment(date).isSame(today));

  // Only the most recent previous day
  const previousDates = Object.keys(dates).filter((date) => moment(date).isSame(oneDayAgo));

  // Show no predictions message if no games today or yesterday
  if (!upcomingDates.length && !previousDates.length) {
    return (
      <div className="no-predictions">
        <p>
          Predictions are typically loaded daily starting at 1AM EST and will be available by 12PM at the latest. 
          While NBA & WNBA require 7 days into the season, NFL requires 3 weeks into the season, and MLB requires 15 days into the season to provide accurate forecasting, there may be days where no games are scheduled, such as during mid-season breaks or All-Star events. 
          If you subscribed on a day without any scheduled games, feel free to reach out and we will gladly provide a free subscription for another day of your choice!
        </p>
        <CheatSheetDropdown sport_id={sport_id} date={today} />
      </div>
    );
  }

  return (
    <div className="sport-container">
      <div className="header">
        <button className="home-button" onClick={() => navigate('/dashboard')}>
          Home
        </button>
        <div className="ribbon-header">{sportName} Game Data</div>
      </div>
      <div className="dates">
        <h2>Dates</h2>
        {upcomingDates.length > 0 && (
          <div>
            {upcomingDates.map((date, dateIndex) => (
              <div className="date-item" key={date}>
                <div className="date">
                  <span onClick={() => toggleDateExpansion(date)}>
                    {date}
                    <img
                      src={expandedDates[date] ? '/assets/icons/misc/collapse_icon.png' : '/assets/icons/misc/expand_icon.png'}
                      alt={expandedDates[date] ? 'Collapse' : 'Expand'}
                      className="expand-collapse-icon"
                    />
                  </span>
                  <div className="date-links">
                    {dateIndex === 0 && (
                      <>
                        <button className="excel-icon" onClick={() => handleGenerateExcel(date)}>
                          <img src="/assets/icons/misc/excel_icon.jpg" alt="Generate Excel" className="icon" />
                        </button>
                        <CheatSheetDropdown sport_id={sport_id} date={date} />
                      </>
                    )}
                  </div>
                </div>
                {expandedDates[date] && (
                  <div className="games-list">
                    {dates[date].map((game, gameIndex) => (
                      <div key={gameIndex} className="game-item">
                        <div className="game-name">{game.game_display_name}</div>
                        <div className="game-links">
                          <button onClick={() => handleShowPdf(date, game.game_id)}>
                            <img src="/assets/icons/misc/pdf_icon.png" alt="Show Details" className="icon" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {previousDates.length > 0 && (
          <div className="previous-dates-section">
            <button className="toggle-button" onClick={() => setExpandedDates((prev) => ({ ...prev, previous: !prev.previous }))}>
              {expandedDates.previous ? 'Hide Previous Dates' : 'Show Previous Dates'}
            </button>
            {expandedDates.previous && (
              <div className="previous-dates-list">
                {previousDates.map((date, index) => (
                  <div key={index} className="date-item">
                    <div className="date">
                      <span onClick={() => toggleDateExpansion(date)}>
                        {date}
                        <img
                          src={expandedDates[date] ? '/assets/icons/misc/collapse_icon.png' : '/assets/icons/misc/expand_icon.png'}
                          alt={expandedDates[date] ? 'Collapse' : 'Expand'}
                          className="expand-collapse-icon"
                        />
                      </span>
                      <div className="date-links">
                        {index === 0 && (
                          <>
                            <CheatSheetDropdown sport_id={sport_id} date={date} />
                            <button className="excel-icon" onClick={() => handleGenerateExcel(date)}>
                              <img src="/assets/icons/misc/excel_icon.jpg" alt="Generate Excel" className="icon" />
                            </button>
                          </>
                        )}
                      </div>
                      {expandedDates[date] && (
                        <div className="games-list">
                          {dates[date].map((game, gameIndex) => (
                            <div key={gameIndex} className="game-item">
                              <div className="game-name">{game.game_display_name}</div>
                              <div className="game-links">
                                <button onClick={() => handleShowPdf(date, game.game_id)}>
                                  <img src="/assets/icons/misc/pdf_icon.png" alt="Show Details" className="icon" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sport;
