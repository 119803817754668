import React from 'react';
import { useParams } from 'react-router-dom';

import NBAGameDetails from './NBAGameDetails';
import WNBAGameDetails from './WNBAGameDetails';
import MLBGameDetails from './MLBGameDetails';
import NFLGameDetails from './NFLGameDetails';

const GameDetailsPage = () => {
  const { sportName } = useParams();

  switch (sportName.toLowerCase()) {
    case 'nba':
      return <NBAGameDetails />;
    case 'wnba':
      return <WNBAGameDetails />;
    case 'mlb':
      return <MLBGameDetails />;
    case 'nfl':
      return <NFLGameDetails />;
    default:
      return <div>Sport not found</div>;
  }
};

export default GameDetailsPage;
