import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/data/CheatSheet.css';

const GoodHistoryCheatSheet = () => {
  const { date } = useParams();
  const navigate = useNavigate();
  const [hotBatterData, setHotBatterData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const handleError = useCallback((error) => {
    if (error.response) {
      if (error.response.status === 403) {
        setError('You are not subscribed to this sport.');
      } else if (error.response.status === 404) {
        setError('Sport not found.');
      } else if (error.response.data && error.response.data.code === 'token_not_valid') {
        navigate('/login');
      } else {
        setError(`An unexpected error occurred: ${error.response.statusText}`);
      }
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/pdf-data/${date}/0/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const hotBatterData = response.data.batters_good_pitcher_history;

        setHotBatterData(hotBatterData);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [date, handleError]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!hotBatterData.length) {
    return <div>No data available for this date.</div>;
  }

  return (
    <div className="cheat-sheet">
      <div className="header">
        <h1>Batters with Good History Cheat Sheet for {date}</h1>
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      </div>
      <div className="player-list">
        {hotBatterData.map((player) => (
          <div key={player.player_id} className="player-section">
            <div className="player-name">{player.player_name}</div>
            <br></br>
            <div className="player-metrics">
              <p>Opposing Pitcher: {player.opposing_pitcher}</p>
              <p>Opposing Pitcher Predicted Innings Pitched: {player.predicted_innings_pitched}</p>
              <p>Opposing Pitcher Predicted Hits Allowed: {player.predicted_hits_allowed}</p>
              <br></br>
              <p>AB vs Opposing Pitcher: {player.ab_against_opposing_pitcher} ABs</p>
              <p>Hits vs Opposing Pitcher: {player.hits_against_opposing_pitcher} Hits</p>
              <p>HRs vs Opposing Pitcher: {player.hrs_against_opposing_pitcher} Hits</p>
              <br></br>
              <p>Total Hits (Last 10): {player.hit_amount} Hits</p>
              <p>Total Home Runs (Last 10): {player.home_run_amount} Home Runs</p>
              <p>Total AB (Last 10): {player.ab_amount} ABs</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoodHistoryCheatSheet;
