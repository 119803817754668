import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/data/CheatSheet.css';

const PlayerCheatSheet = () => {
  const navigate = useNavigate();
  const [playerData, setPlayerData] = useState({});
  const [error, setError] = useState('');
  const [expandedTeams, setExpandedTeams] = useState({});
  const [expandedPositions, setExpandedPositions] = useState({});

  // Handle any API errors
  const handleError = useCallback((error) => {
    if (error.response) {
      if (error.response.status === 403) {
        setError('You are not subscribed to this sport.');
      } else if (error.response.status === 404) {
        setError('Sport not found.');
      } else if (error.response.data && error.response.data.code === 'token_not_valid') {
        navigate('/login');
      } else {
        setError(`An unexpected error occurred: ${error.response.statusText}`);
      }
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
  }, [navigate]);

  // Fetch cheat sheet data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/cheat-sheets/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Organize data by teams and positions
        const qbData = response.data.qb_cheat_sheet || [];
        const rbData = response.data.rb_cheat_sheet || [];
        const wrData = response.data.wr_cheat_sheet || [];
        const teData = response.data.te_cheat_sheet || [];

        const organizedData = {};

        const processData = (data, position) => {
          data.forEach(player => {
            const team = player.opposing_team;
            if (!organizedData[team]) {
              organizedData[team] = {};
            }
            if (!organizedData[team][position]) {
              organizedData[team][position] = [];
            }
            organizedData[team][position].push(player);
          });
        };

        processData(qbData, 'QB');
        processData(rbData, 'RB');
        processData(wrData, 'WR');
        processData(teData, 'TE');

        setPlayerData(organizedData);
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, [handleError]);

  // Toggle expansion of team sections
  const toggleTeamExpansion = (team) => {
    setExpandedTeams((prev) => ({
      ...prev,
      [team]: !prev[team],
    }));
  };

  // Toggle expansion of position sections
  const togglePositionExpansion = (team, position) => {
    setExpandedPositions((prev) => ({
      ...prev,
      [team]: {
        ...prev[team],
        [position]: !prev[team]?.[position],
      },
    }));
  };

  // Error rendering
  if (error) {
    return <div>{error}</div>;
  }

  // Loading state
  if (!Object.keys(playerData).length) {
    return <div>Loading...</div>;
  }

  // Sort teams alphabetically
  const sortedTeams = Object.keys(playerData).sort();

  // Render the cheat sheet
  return (
    <div className="cheat-sheet">
      <div className="header">
        <h1>Player Cheat Sheet</h1>
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      </div>
      <div className="team-list">
        {sortedTeams.map((team) => (
          <div key={team} className="team-section">
            <div className="team-header" onClick={() => toggleTeamExpansion(team)}>
              {team} (View Opposing Matchups)
              <img
                src={expandedTeams[team] ? '/assets/icons/misc/collapse_icon.png' : '/assets/icons/misc/expand_icon.png'}
                alt={expandedTeams[team] ? 'Collapse' : 'Expand'}
                className="expand-collapse-icon"
              />
            </div>
            {expandedTeams[team] && (
              <div className="position-list">
                {Object.keys(playerData[team]).map((position) => (
                  <div key={position} className="position-section">
                    <div className="position-header" onClick={() => togglePositionExpansion(team, position)}>
                      {position} (View Players)
                      <img
                        src={expandedPositions[team]?.[position] ? '/assets/icons/misc/collapse_icon.png' : '/assets/icons/misc/expand_icon.png'}
                        alt={expandedPositions[team]?.[position] ? 'Collapse' : 'Expand'}
                        className="expand-collapse-icon"
                      />
                    </div>
                    {expandedPositions[team]?.[position] && (
                      <div className="player-list">
                        {playerData[team][position].map((player) => (
                          <div key={player.player_name} className="player-row">
                            <div className="player-name">{position} - {player.player_name}</div>
                            <br></br>
                            {position === 'QB' && (
                              <>
                                <div className="metric">QB Predicted Completions: {player.predicted_passing_completions}</div>
                                <div className="metric">QB Predicted Attempts: {player.predicted_passing_attempts}</div>
                                <div className="metric">QB Predicted Passing Yards: {player.predicted_passing_yards}</div>
                                <div className="metric">QB Predicted TDs: {player.predicted_passing_tds}</div>
                                <br></br>
                                <div className="metric">Avg Passing Yards Allowed to QB by {player.opposing_team}: {player.average_passing_yards_allowed}</div>
                                <div className="metric">Avg Points Allowed to Opposing Offense by {player.opposing_team}: {player.average_points_allowed}</div>
                                <br></br>
                                <div className="metric">Avg Points Scored by {player.player_name} Team: {player.predicted_qb_team_average_points_scored}</div>
                              </>
                            )}
                            {position === 'RB' && (
                              <>
                                <div className="metric">RB Predicted Rushing Attempts: {player.predicted_rushing_carries}</div>
                                <div className="metric">RB Predicted Rushing Yards: {player.predicted_rushing_yards}</div>
                                <div className="metric">RB Predicted Rushing TDs: {player.predicted_rushing_tds}</div>
                                <div className="metric">RB Predicted Receiving Yards: {player.predicted_receiving_yards}</div>
                                <br></br>
                                <div className="metric">Avg Rushing Yards Allowed to RB by {player.opposing_team}: {player.average_rushing_yards_allowed}</div>
                                <div className="metric">Avg Rushing Yards Allowed Rank to RB by {player.opposing_team}: {player.average_rushing_yards_allowed_rank}</div>
                              </>
                            )}
                            {position === 'WR' && (
                              <>
                                <div className="metric">WR Predicted Targets: {player.predicted_receiving_targets}</div>
                                <div className="metric">WR Predicted Receptions: {player.predicted_receiving_receptions}</div>
                                <div className="metric">WR Predicted Receiving Yards: {player.predicted_receiving_yards}</div>
                                <br></br>
                                <div className="metric">Avg Receiving Yards Allowed to WR by {player.opposing_team}: {player.average_receiving_yards_allowed}</div>
                                <div className="metric">Avg Receiving Yards Allowed Rank to WR by {player.opposing_team}: {player.average_receiving_yards_allowed_rank}</div>
                              </>
                            )}
                            {position === 'TE' && (
                              <>
                                <div className="metric">TE Predicted Targets: {player.predicted_receiving_targets}</div>
                                <div className="metric">TE Predicted Receptions: {player.predicted_receiving_receptions}</div>
                                <div className="metric">TE Predicted Receiving Yards: {player.predicted_receiving_yards}</div>
                                <br></br>
                                <div className="metric">Avg Receiving Yards Allowed to TE by {player.opposing_team}: {player.average_receiving_yards_allowed}</div>
                                <div className="metric">Avg Receiving Yards Allowed Rank to TE by {player.opposing_team}: {player.average_receiving_yards_allowed_rank}</div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayerCheatSheet;
