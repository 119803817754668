/* 
# ##################################################################################################
# Imports
# ##################################################################################################
*/

import React, { useState } from 'react';
import '../../styles/core/Faq.css';
import faqData from '../misc/faqData'; 

/* 
# ##################################################################################################
# Core Logic
# ##################################################################################################
*/

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {Object.entries(faqData).map(([question, answer], index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAnswer(index)}>
            <h3>{question}</h3>
            <span>{activeIndex === index ? '-' : '+'}</span>
          </div>
          {activeIndex === index && <div className="faq-answer"><p>{answer}</p></div>}
        </div>
      ))}
    </div>
  );
};

export default Faq;

/* 
# ##################################################################################################
# END
# ##################################################################################################
*/